import { useCallback } from "react";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { Button } from "react-bootstrap";
import { BinIcon } from "../../components/Icon/Icon";

function PartnerPostbacksActionButton(props) {
    const { active, iconName, item, onClick, tooltip } = props;

    const handleClick = useCallback(() => {
        onClick(item);
    }, [item, onClick]);

    return <Tooltip text={tooltip}>
        <Button
            active={active}
            variant='info'
            size='sm'
            onClick={handleClick}
        >
            <BinIcon name={iconName} style={{ fill: 'var(--icon-primary-color)' }}/>
        </Button>
    </Tooltip>;
}

export default PartnerPostbacksActionButton;
