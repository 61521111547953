import { produce } from 'immer';
import _ from "lodash";
import {
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTRATION_FAILURE,
    REGISTRATION_START,
    REGISTRATION_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS
} from "../modules/Auth/authConstants.js";
import { APP_INIT_FAILURE, APP_INIT_SUCCESS, CLOSE_MODAL, OPEN_MODAL } from "./appConstants";

export const initialState = {
    availableCountries: [],
    availableLocales: [],

    inited: false,
    lang: null,
    locale: null,

    loggedIn: false,
    loggedInProcess: false,
    loginError: null,
    registrationError: null,

    partner: {},

    openedModal: null,
    openedModalPrevious: null,

    features: {},

    postbacks_types: [],
    postbacks_url_tokens: [],

    passwordForgotSent: false,
    passwordForgotError: false,

    passwordResetOk: false,
    passwordResetError: false,
};

const appReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {

            case APP_INIT_SUCCESS:
                draft.availableCountries = action.payload.availableCountries || [];
                draft.availableLocales = action.payload.availableLocales || [];

                draft.inited = true;

                draft.lang = action.payload.lang;
                draft.locale = action.payload.locale;

                draft.postbacks_types = action.payload.postbacks_types || [];
                draft.postbacks_url_tokens = action.payload.postbacks_url_tokens || [];

                draft.loggedIn = !!action.payload.loggedIn;
                draft.partner = action.payload.partner || {};
                draft.features = action.payload.features || {};
                break;

            case APP_INIT_FAILURE:
                draft.inited = true;
                break;

            case OPEN_MODAL:
                if (_.isObject(action.payload)) {
                    draft.openedModal = action.payload;
                } else {
                    draft.openedModal = {
                        name: action.payload
                    };
                }
                break;

            case CLOSE_MODAL:
                draft.openedModalPrevious = Object.assign({}, draft.openedModal);
                draft.openedModal = null;
                break;

            case LOGIN_START:
                draft.loginError = null;
                draft.loggedInProcess = true;
                break;

            case LOGIN_SUCCESS:
                draft.loggedIn = true;
                draft.loggedInProcess = false;
                break;

            case LOGIN_FAILURE:
                draft.loginError = action.payload;
                draft.loggedInProcess = false;
                break;

            case REGISTRATION_START:
                draft.registrationError = null;
                draft.loggedInProcess = true;
                break;

            case REGISTRATION_SUCCESS:
                draft.registration = action.payload;
                draft.loggedInProcess = false;
                break;

            case REGISTRATION_FAILURE:
                draft.registrationError = action.payload;
                draft.loggedInProcess = false;
                break;

            case FORGOT_PASSWORD_START:
                draft.passwordForgotError = false;
                break;
            case FORGOT_PASSWORD_SUCCESS:
                draft.passwordForgotSent = true;
                break;
            case FORGOT_PASSWORD_FAILURE:
                draft.passwordForgotError = action.payload;
                break;

            case RESET_PASSWORD_START:
                draft.passwordResetError = false;
                break;
            case RESET_PASSWORD_SUCCESS:
                draft.passwordResetOk = true;
                break;
            case RESET_PASSWORD_FAILURE:
                draft.passwordResetError = action.payload;
                break;

            case '@@router/LOCATION_CHANGE':
                draft.passwordForgotError = false;
                draft.passwordForgotSent = false;
                draft.passwordResetError = false;
                draft.passwordResetOk = false;
                break;

            case LOGOUT_SUCCESS:
                return {
                    ...initialState,
                    availableCountries: state.availableCountries,
                    inited: true,
                    lang: state.lang,
                    locale: state.locale,
                };

            default:
                return state;
        }
    });

export default appReducer;
