import { produce } from "immer";
import _ from "lodash";
import {
    ADD_PARTNER_CAMPAIGN_SUCCESS,
    ADD_PARTNER_POSTBACK_FAILURE,
    ADD_PARTNER_POSTBACK_START,
    ADD_PARTNER_POSTBACK_SUCCESS,
    DELETE_PARTNER_CAMPAIGN_SUCCESS,
    DELETE_PARTNER_POSTBACK_SUCCESS,
    LOAD_PARTNER_CAMPAIGNS_SUCCESS,
    LOAD_PARTNER_POSTBACKS_SUCCESS,
    LOAD_PARTNER_STATISTICS_FAILURE,
    LOAD_PARTNER_STATISTICS_START,
    LOAD_PARTNER_STATISTICS_SUCCESS,
    REFRESH_API_KEY_FAILURE,
    REFRESH_API_KEY_START,
    REFRESH_API_KEY_SUCCESS,
    RESET_ADD_PARTNER_POSTBACK_ERROR,
    RESET_UPDATE_PARTNER_POSTBACK_ERROR,
    UPDATE_PARTNER_CAMPAIGN_SUCCESS,
    UPDATE_PARTNER_PAYMENT_FAILURE,
    UPDATE_PARTNER_PAYMENT_START,
    UPDATE_PARTNER_PAYMENT_SUCCESS,
    UPDATE_PARTNER_POSTBACK_FAILURE,
    UPDATE_PARTNER_POSTBACK_START,
    UPDATE_PARTNER_POSTBACK_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_UPDATE_PASSWORD_FAILURE,
    USER_UPDATE_PASSWORD_START,
    USER_UPDATE_START
} from "./partnerConstants";

const initialState = {
    campaignsByOfferId: {},
    isAddPostbackLoading: false,
    isRefreshApiKeyLoading: false,
    isStatisticsLoading: false,
    isUpdatePostbackLoading: false,
    statistics: [],
    updatingPayment: false,
    postbacks: {},
    addPostbackError: '',
    updatePostbackError: '',
    userUpdateError: false,
    userUpdatePasswordError: false,
};

const partnerReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case LOAD_PARTNER_CAMPAIGNS_SUCCESS:
                draft.campaignsByOfferId[action.payload.offerId] = action.payload.campaignsList;
                break;
            case ADD_PARTNER_CAMPAIGN_SUCCESS:
                draft.campaigns.push(action.payload);
                break;
            case UPDATE_PARTNER_CAMPAIGN_SUCCESS:
                const campaignUpdateIndex = _.findIndex(draft.campaigns, { id: action.payload.id });
                draft.campaigns.splice(campaignUpdateIndex, 1, action.payload);
                break;
            case DELETE_PARTNER_CAMPAIGN_SUCCESS:
                const campaignDeleteIndex = _.findIndex(draft.campaigns, { id: action.payload.id });
                draft.campaigns.splice(campaignDeleteIndex, 1);
                break;
            case UPDATE_PARTNER_PAYMENT_START:
                draft.updatingPayment = true;
                break;
            case UPDATE_PARTNER_PAYMENT_SUCCESS:
                draft.updatingPayment = false;
                break;
            case UPDATE_PARTNER_PAYMENT_FAILURE:
                draft.updatingPayment = false;
                break;
            case LOAD_PARTNER_STATISTICS_START:
                draft.isStatisticsLoading = true;
                break;
            case LOAD_PARTNER_STATISTICS_SUCCESS:
                draft.statistics = action.payload;
                draft.isStatisticsLoading = false;
                break;
            case LOAD_PARTNER_STATISTICS_FAILURE:
                draft.isStatisticsLoading = false;
                break;

            case LOAD_PARTNER_POSTBACKS_SUCCESS:
                draft.postbacks[action.payload.offer_id] = action.payload.postbacks;
                break;

            case ADD_PARTNER_POSTBACK_START:
                draft.isAddPostbackLoading = true;
                draft.addPostbackError = '';
                break;
            case ADD_PARTNER_POSTBACK_SUCCESS:
                draft.isAddPostbackLoading = false;
                break;
            case ADD_PARTNER_POSTBACK_FAILURE:
                draft.isAddPostbackLoading = false;
                draft.addPostbackError = action.payload;
                break;
            case UPDATE_PARTNER_POSTBACK_START:
                draft.updatePostbackError = '';
                draft.isUpdatePostbackLoading = true;
                break;
            case UPDATE_PARTNER_POSTBACK_SUCCESS:
                draft.isUpdatePostbackLoading = false;
                break;
            case UPDATE_PARTNER_POSTBACK_FAILURE:
                draft.updatePostbackError = action.payload;
                draft.isUpdatePostbackLoading = false;
                break;
            case DELETE_PARTNER_POSTBACK_SUCCESS:
                break;
            case RESET_ADD_PARTNER_POSTBACK_ERROR:
                draft.addPostbackError = '';
                break;
            case RESET_UPDATE_PARTNER_POSTBACK_ERROR:
                draft.updatePostbackError = '';
                break;
            case REFRESH_API_KEY_START:
                draft.isRefreshApiKeyLoading = true;
                break;
            case REFRESH_API_KEY_SUCCESS:
                draft.isRefreshApiKeyLoading = false;
                break;
            case REFRESH_API_KEY_FAILURE:
                draft.isRefreshApiKeyLoading = false;
                break;

            case USER_UPDATE_START:
                draft.userUpdateError = false;
                break;
            case USER_UPDATE_FAILURE:
                draft.userUpdateError = action.payload;
                break;

            case USER_UPDATE_PASSWORD_START:
                draft.userUpdatePasswordError = false;
                break;
            case USER_UPDATE_PASSWORD_FAILURE:
                draft.userUpdatePasswordError = action.payload;
                break;

            default:
                return state;
        }
    });

export default partnerReducer;
