import { forwardRef } from "react";
import { FormControl } from "react-bootstrap";
import { DatePickerInputStyled } from "./styled/DatePickerStyled";
import DatePickerLib from 'react-datepicker';
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { BinIcon } from "../Icon/Icon.jsx";

const DatePickerInput = forwardRef((props, ref) => {
    return (
        <DatePickerInputStyled>
            <FormControl ref={ref} className="pe-4" type="text" {...props}/>
            <BinIcon name={'IcDocCopy'} onClick={props.onClick}/>
        </DatePickerInputStyled>
    );
});

function DatePicker({selectedDate, onChange, ...datePickerProps}) {
    const {locale} = useLangContext();
    return <DatePickerLib
        customInput={<DatePickerInput/>}
        locale={locale}
        onChange={onChange}
        selected={selectedDate}
        {...datePickerProps}
    />
}

export default DatePicker;
