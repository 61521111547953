export const PARTNER_STATISTICS_GROUP_TYPE = {
    CAMPAIGN: 'campaign',
    CHANNEL: 'channel',
    DAY: 'day',
    MONTH: 'month',
    WEEK: 'week',
    USER_ID: 'user_id',
}

export const PARTNER_STATISTICS_CAMPAIGN_COLUMNS_GROUP_TYPES = [
    PARTNER_STATISTICS_GROUP_TYPE.DAY,
    PARTNER_STATISTICS_GROUP_TYPE.WEEK,
    PARTNER_STATISTICS_GROUP_TYPE.MONTH,
    PARTNER_STATISTICS_GROUP_TYPE.USER_ID,
];

export const PARTNER_STATISTICS_REGISTERED_COLUMN_GROUP_TYPES = [
    PARTNER_STATISTICS_GROUP_TYPE.DAY,
    PARTNER_STATISTICS_GROUP_TYPE.WEEK,
    PARTNER_STATISTICS_GROUP_TYPE.MONTH,
    PARTNER_STATISTICS_GROUP_TYPE.CAMPAIGN,
    PARTNER_STATISTICS_GROUP_TYPE.CHANNEL,
];

export const PARTNER_STATISTICS_REGISTERED_AT_COLUMN_GROUP_TYPES = [
    PARTNER_STATISTICS_GROUP_TYPE.USER_ID,
];
