export const formReferralLink = ({ campaign, channel, offerId, refshare, url }) => {
    let urlInstance;
    try {
        urlInstance = new URL(url);
    } catch (e) {
        return '';
    }
    const isOneLink = url.indexOf('onelink') > 0;
    if (refshare) urlInstance.searchParams.set(isOneLink ? 'af_sub4' : 'a', refshare);
    if (campaign) urlInstance.searchParams.set(isOneLink ? 'c' : 'ac', campaign);
    if (channel) urlInstance.searchParams.set(isOneLink ? 'af_channel' : 'ch', channel);
    if (offerId) urlInstance.searchParams.set(isOneLink ? 'af_sub3' : 'of', offerId);
    return urlInstance.toString();
};
