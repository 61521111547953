import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getOpenedModalParamsSelector } from "../../app/appSelectors";
import { closeModalAction } from "../../app/appActions";
import { useCallback, useEffect } from "react";
import {
    getPartnerAddPostbackErrorSelector,
    getPartnerIsAddPostbackLoadingSelector,
} from "../Partner/partnerSelectors";
import { addPartnerPostbackAction, resetAddPartnerPostbackErrorAction, } from "../Partner/partnerActions";
import { PARTNER_POSTBACK_FORM_TYPES } from "./partnerPostbacksConstants.js";
import PartnerPostbackForm from "./PartnerPostbackForm.jsx";
import { ModalContentStyled, ModalInformationStyled } from "../Modals/styled/ModalsStyled.js";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";

function PartnerAddPostbackModal(props) {
    const {
        addPartnerPostbackAction,
        addPostbackError,
        closeModalAction,
        isAddPostbackLoading,
        openedModalParams,
        resetAddPartnerPostbackErrorAction,
    } = props;
    const {getLangPath} = useLangContext();

    useEffect(() => {
        if (!openedModalParams) {
            closeModalAction();
        }
        return () => {
            resetAddPartnerPostbackErrorAction();
        }
    }, [closeModalAction, openedModalParams, resetAddPartnerPostbackErrorAction]);

    const handleSubmit = useCallback((data) => {
        addPartnerPostbackAction(data);
    }, [addPartnerPostbackAction]);

    return <ModalInformationStyled>
        <Modal.Header closeButton>
            {getLangPath(`postbacks.form.${PARTNER_POSTBACK_FORM_TYPES.ADD}.title`)}
        </Modal.Header>
        <ModalContentStyled>
            <PartnerPostbackForm
                campaignId={openedModalParams?.campaignId}
                error={addPostbackError}
                formType={PARTNER_POSTBACK_FORM_TYPES.ADD}
                isLoading={isAddPostbackLoading}
                offerId={openedModalParams?.offerId}
                onCancel={closeModalAction}
                onSubmit={handleSubmit}
                resetError={resetAddPartnerPostbackErrorAction}
            />
        </ModalContentStyled>
    </ModalInformationStyled>
}

const mapStateToProps = createStructuredSelector({
    addPostbackError: getPartnerAddPostbackErrorSelector,
    isAddPostbackLoading: getPartnerIsAddPostbackLoadingSelector,
    openedModalParams: getOpenedModalParamsSelector,
});

const mapDispatchToProps = {
    addPartnerPostbackAction,
    closeModalAction,
    resetAddPartnerPostbackErrorAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAddPostbackModal);
