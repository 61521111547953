import { useResponsive } from "../components/responsive";
import PageErrorContent from "./PageErrorContent.jsx";
import AuthWrapper from "../modules/Auth/components/AuthWrapper.jsx";
import { LoginStyled } from "../modules/Auth/components/styled/LoginStyled.js";

function PageError({
    children,
    headerPanelComponent,
    ...pageErrorContentProps
}) {
    const { isSmallMaxWidth } = useResponsive();

    return <AuthWrapper>
        <LoginStyled>
            <PageErrorContent {...pageErrorContentProps}>
                {children}
            </PageErrorContent>
        </LoginStyled>
    </AuthWrapper>
}

export default PageError;

