import _ from "lodash";
import moment from "moment";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { PopupContext } from "./PopupContext.js";
import { PopupStyled } from "./PopupStyled.js";

const PopupProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const timer = setInterval(() => {
            const newMessages = _.filter(messages, item => moment(item.close) > moment());
            if (newMessages.length !== messages.length) setMessages(newMessages);
        }, 300);
        return () => {
            clearInterval(timer);
        }
    }, [messages]);

    const hideMessage = useCallback((uuid) => {
        setMessages(messages => _.filter(messages, item => item.uuid !== uuid));
    }, []);

    const addToQueue = useCallback((params) => {
        const uuid = uuidv4();
        setMessages((messages) => [].concat(messages).concat([
            {
                uuid,
                close: moment().add(5, 's').toISOString(),
                ...params,
            }
        ]));
    }, []);

    const catchPopup = useCallback((error) => {
        addToQueue({
            title: error.status,
            text: error.data?.messageText || error.statusText,
        });
    }, [addToQueue]);

    return <>
        <PopupContext.Provider value={{ catchPopup }}>
            <PopupStyled>
                {
                    messages.slice(-5).map(item => <Fragment key={item.uuid}>
                        <div onClick={() => hideMessage(item.uuid)}>
                            <Alert variant={'danger'}>
                                <h6>{item.title}</h6>
                                <div>{item.text}</div>
                            </Alert>
                        </div>
                    </Fragment>)
                }
            </PopupStyled>
            {children}
        </PopupContext.Provider>
    </>
}

export default PopupProvider;
