import styled from 'styled-components';
import { BREAKPOINTS } from "../../../app/appConstants";

export const StatisticsStyled = styled.div`
`;

export const StatisticsTableStyled = styled.div`
    overflow: auto;
    margin: 0 -16px;
    padding: 0 16px;
    tfoot {
        font-weight: bold;
    }
`;

export const PartnerStatisticsMainRowStyled = styled.div`
    margin: -8px;
    display: flex;
    flex-wrap: wrap;
`;

export const PartnerStatisticsMainColumnStyled = styled.div`
    padding: 8px;
    flex: 1 0 50%;
    margin-bottom: 16px;
    @media (max-width: ${BREAKPOINTS.extraLarge}px) {
        flex-basis: 100%;
    }
`;

export const PartnerStatisticsFilterContainerStyled = styled.div`
    margin: -8px;
    display: flex;
`;

export const PartnerStatisticsFilterColumnStyled = styled.div`
    padding: 8px;
`;

export const PartnerStatisticsPresetContainerStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: -24px;

    .btn-link {
        margin-top: 8px;
        padding: 4px 0;
        margin-right: 16px;
        color: var(--color-secondary-active);
        border-bottom: 1px dashed var(--color-secondary-active);
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: none;
            color: var(--color-secondary-highlight);
            border-bottom: 1px dashed var(--color-secondary-highlight);
            box-shadow: none;
        }
    }
`;
