import { CampaignForm } from './CampaignForm';
import { Modal } from 'react-bootstrap';
import { useLangContext } from '../../providers/LangProvider/LangProviderContext';
import { ModalInformationStyled } from '../Modals/styled/ModalsStyled';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { loadPartnerCampaignsAction } from '../Partner/partnerActions';
import { usePopupContext } from '../Popup/PopupContext';
import { deleteRequest, putRequest } from '../../app/request';

function CampaignUpdateModal(props) {
    const { closeModal, params } = props;
    const dispatch = useDispatch();
    const { getLangPath } = useLangContext();
    const { catchPopup } = usePopupContext();

    useEffect(() => {
        if (!params?.offerInfo) {
            closeModal();
        }
    }, [closeModal, params]);

    const handleDeleteButtonClick = useCallback(() => {
        const isConfirmed = window.confirm(getLangPath('campaigns.confirm'));
        if (isConfirmed) {
            deleteRequest(`/partner/offers/${params.offerInfo.offer_id}/campaigns/${params.campaignId}`, {})
                .then(() => {
                    closeModal();
                    dispatch(loadPartnerCampaignsAction(params.offerInfo.offer_id));
                })
                .catch(e => {
                    catchPopup(e.response);
                });
        }
    }, [catchPopup, closeModal, dispatch, getLangPath, params]);

    const updateCampaignFormSubmit = useCallback((campaignParams) => {
        putRequest(`/partner/offers/${params.offerInfo.offer_id}/campaigns/${params.campaignId}`, campaignParams)
            .then(() => {
                closeModal();
                dispatch(loadPartnerCampaignsAction(params.offerInfo.offer_id));
            })
            .catch(e => {
                catchPopup(e.response);
            });
    }, [catchPopup, closeModal, dispatch, params]);

    const updateCampaignFormCancel = useCallback(() => {
        closeModal();
    }, [closeModal]);

    if (!params) {
        return null;
    }

    return <ModalInformationStyled>
        <Modal.Header closeButton>
            {getLangPath('campaigns.update_campaign')}
        </Modal.Header>
        <CampaignForm
            channelDefaultValue={params.campaignChannel}
            nameDefaultValue={params.campaignName}
            onDelete={handleDeleteButtonClick}
            onCancel={updateCampaignFormCancel}
            offerInfo={params.offerInfo}
            onSubmit={updateCampaignFormSubmit}
        />
    </ModalInformationStyled>;
}

export default CampaignUpdateModal;
