import styled from "styled-components";

export const PromoStyled = styled.div`
    font-size: 1.5em;
    font-weight: bold;
    
    svg {
        stroke: var(--bs-link-color);
        margin: 0 12px;
        font-size: 1em !important;
    }
`;
