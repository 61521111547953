import { MenuItemStyled, MenuStyled } from "./styled/MenuStyled.js";
import { MENU_ITEMS } from "./menuConstants.js";
import { Fragment } from "react";
import { Link, matchPath } from "react-router-dom";
import classNames from "classnames";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { generatePath, useLocation } from 'react-router';
import { BinIcon } from '../../components/Icon/Icon';

function Menu() {
    const {getLangPath} = useLangContext();
    const location = useLocation();

    return <MenuStyled>
        {
            MENU_ITEMS.map(item => <Fragment key={item.name}>
                <MenuItemStyled className={classNames({
                    'active': matchPath(location.pathname, {path: item.path, exact: item.exact ?? true}),
                })}>
                    <Link to={generatePath(item.path)}>
                        <BinIcon name={item.icon} size="16px"/>
                        {getLangPath(`menu.${item.name}`)}
                    </Link>
                </MenuItemStyled>
            </Fragment>)
        }
    </MenuStyled>
}

export default Menu;
