import { put } from 'redux-saga/effects';

export function* failurePut(e, action) {
    const messageCode = e.response?.data?.messageCode || e.messageCode || 'error.default_message';
    if (action) {
        yield put(action(messageCode));
    }

    yield put(openNotify({messageCode, type: 'FAILURE'}));
}

export const openNotify = ({type, message}) => ({
    type: 'OPEN_NOTIFY',
    payload: {type, message},
});
