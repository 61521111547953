import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS } from "../../../app/appConstants";

export const GlobalModalStyled = createGlobalStyle`

  @media (min-width: ${BREAKPOINTS.small}px) {
    .modal-lg, .modal-xl {
      max-width: 800px;
    }
  }

  @media (min-width: ${BREAKPOINTS.medium}px) {
    .modal-lg, .modal-xl {
      max-width: 800px;
    }
  }

  @media (min-width: ${BREAKPOINTS.large}px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  
    .modal-dialog {
        
    }
    
    .modal-fullscreen {
        .modal-header {
            position: fixed;
            top:0;
            width: 100%;
            z-index: 10;
        }
        .modal-content {
            padding-top: 64px;
        }
    }
    
    .modal-header {
        background: var(--app-bg-modal-header-color);
        padding: 16px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        border-bottom: none;
    }
    
    .btn-close {
      opacity: 0.8;
      background: transparent url("/assets/icons/ic_x_mark.svg") center/1em auto no-repeat;

      :hover {
        opacity: 1;
      }
    }
    
    .modal-content {
        background: var(--app-bg-modal-color);
    }
`;