import styled from 'styled-components';

export const OffersContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 950px;
    margin: 0 auto;
    padding-bottom: 32px;
`;
