import { Form } from "react-bootstrap";
import { LINKS } from "../../../app/appConstants";
import { useLangContext } from "../../../providers/LangProvider/LangProviderContext.js";
import { LoginBoxAcceptTermsContainerStyled } from "./styled/LoginStyled.js";

function RegistrationAcceptPolicy(props) {
    const { className, error, onChange } = props;
    const { getLangPath } = useLangContext();
    const checkboxLabelAcceptPolicyHtml = getLangPath('auth.field_accept', {
        affiliate_agreement_link: LINKS.affiliateAgreementDoc,
    });

    return <LoginBoxAcceptTermsContainerStyled className={className}>
        <Form.Group>
            <Form.Check type={'checkbox'} id={'accept-rules'}>
                <Form.Check.Input name="accept-rules" isInvalid={!!error} onChange={onChange}/>
                <Form.Check.Label dangerouslySetInnerHTML={{ __html: checkboxLabelAcceptPolicyHtml }}/>
                {
                    !!error && (
                        <Form.Control.Feedback type="invalid">
                            {getLangPath(error)}
                        </Form.Control.Feedback>
                    )
                }
            </Form.Check>
        </Form.Group>
    </LoginBoxAcceptTermsContainerStyled>
}

export default RegistrationAcceptPolicy;
