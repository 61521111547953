import { Loader } from '../../../components/Loader/Loader';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { confirmEmailAction } from '../authActions';

function Confirm() {
    const dispatch = useDispatch();
    const { search } = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(search);
        const email = params.get('email');
        const code = params.get('code');
        dispatch(confirmEmailAction({
            email,
            code,
        }));
    }, [dispatch, search]);

    return <Loader />;
}

export default Confirm;
