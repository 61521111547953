import { Accordion, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { deletePartnerPostbackAction, loadPartnerPostbacksAction, } from '../Partner/partnerActions.js';
import { getPostbacksByOfferIdSelector, } from '../Partner/partnerSelectors.js';
import { closeModalAction, openModalAction } from '../../app/appActions.js';
import { MODALS_NAME } from '../Modals/modalsConstants.js';
import { useLangContext } from '../../providers/LangProvider/LangProviderContext.js';
import PartnerPostbacksActionButton from "./PartnerPostbacksActionButton";
import { CampaignsHeaderStyled, CampaignsStyled, CampaignsTableStyled } from "../Campaigns/styled/CampaignsStyled";

function PartnerPostbacks(props) {
    const {
        campaignId = null,
        offerId,
    } = props;
    const postbacksList = useSelector(getPostbacksByOfferIdSelector(offerId));
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();
    const [openedModal, setOpenedModal] = useState({});

    const getPostbacks = useCallback(() => {
        dispatch(loadPartnerPostbacksAction({ offer_id: offerId }));
    }, [dispatch, offerId]);

    const closeModal = useCallback(() => {
        dispatch(closeModalAction());
    }, [dispatch]);

    const handleAddPostbackButtonClick = useCallback((e) => {
        e.stopPropagation();
        dispatch(openModalAction({
            name: MODALS_NAME.PARTNER_ADD_POSTBACK,
            params: { campaignId, offerId },
        }));
    }, [dispatch, campaignId, offerId]);

    const handleUpdateActionClick = useCallback((item) => {
        dispatch(openModalAction({
            name: MODALS_NAME.PARTNER_UPDATE_POSTBACK,
            params: { campaignId, item, offerId },
        }));
    }, [dispatch, campaignId, offerId]);

    const handleDeleteActionClick = useCallback((item) => {
        const isConfirmed = window.confirm(getLangPath('postbacks.confirm'));
        if (isConfirmed) {
            dispatch(deletePartnerPostbackAction({ offer_id: offerId, postback_id: item.id }));
            getPostbacks();
            closeModal();
        }
    }, [closeModal, dispatch, getLangPath, getPostbacks, offerId]);

    useEffect(() => {
        getPostbacks();
    }, [getPostbacks]);

    return <>
        <CampaignsStyled>
            <Accordion defaultActiveKey={'0'}>
                <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>
                        <CampaignsHeaderStyled>
                            <h3 className={'mb-0'}>{getLangPath('postbacks.title')}</h3>
                            <Button variant={'info'} as={'div'}
                                    onClick={handleAddPostbackButtonClick}>
                                {getLangPath('postbacks.add_postback_url_button')}
                            </Button>
                        </CampaignsHeaderStyled>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CampaignsTableStyled>
                            <thead>
                            <tr>
                                <th className={'name'}>{getLangPath('postbacks.type')}</th>
                                <th className={'link'}>{getLangPath('postbacks.url')}</th>
                                <th className={'control'}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                postbacksList.map((item) => (
                                    <Fragment key={item.id}>
                                        <tr>
                                            <td className={'name'}>{item.type}</td>
                                            <td className={'link'}>{item.url}</td>
                                            <td className='d-flex gap-2 justify-content-center'>
                                                <PartnerPostbacksActionButton
                                                    item={item}
                                                    iconName='IcPencil'
                                                    onClick={handleUpdateActionClick}
                                                    tooltip={getLangPath('postbacks.edit')}
                                                />
                                                <PartnerPostbacksActionButton
                                                    item={item}
                                                    iconName='IcXMark'
                                                    onClick={handleDeleteActionClick}
                                                    tooltip={getLangPath('postbacks.delete')}
                                                />
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                            </tbody>
                        </CampaignsTableStyled>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </CampaignsStyled>
    </>;
}

export default PartnerPostbacks;
