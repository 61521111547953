import styled from 'styled-components';

export const DatePickerInputStyled = styled.div`
    position: relative;
    
    svg {
        position: absolute;
        top: 50%;
        right: 10px;
        color: var(--color-secondary-active);
        transform: translateY(-50%);
    }
`;
