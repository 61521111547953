import { fork } from 'redux-saga/effects';
import { authSagas } from "../modules/Auth/authSaga.js";
import { partnerSagas } from "../modules/Partner/partnerSagas.js";
import { appSagas } from "./appSagas";

export function* coreSagas() {
    yield fork(appSagas);
    yield fork(authSagas);
    yield fork(partnerSagas);
}
