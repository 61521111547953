import styled from "styled-components";

export const SliderIconWrapperStyled = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
`;

export const SliderToggleComponentWrapperStyled = styled.span`
    flex: 1;
    display: flex;
`;

export const SliderRowItemStyled = styled.div`
    &.dropdown-item {
        font-weight: unset;

        span {
            padding-left: 12px;
        }
    }
`;
