import { call, put, takeEvery } from 'redux-saga/effects';
import { appInitFailure, appInitSuccess, } from './appActions';
import { APP_INIT_START, CLOSE_MODAL, OPEN_MODAL } from './appConstants';
import { appHistoryPushWithSearchParams } from "./appHistory.js";
import { failurePut } from './failurePut';
import { getRequest } from './request';
import _ from "lodash";

export function* appInitStartSaga() {
    try {
        const data = yield call(getRequest, '/init');

        yield put(appInitSuccess(data));
    } catch (e) {
        yield call(failurePut, e, appInitFailure);
    }
}


export function openModalSaga({ payload }) {
    try {
        const currentPayload = _.isObject(payload) ? payload: { name: payload };
        appHistoryPushWithSearchParams({
            m: currentPayload.name,
            tab: currentPayload.tab,
        });
    } catch (e) {

    }
}

export function closeModalSaga() {
    try {
        appHistoryPushWithSearchParams({
            m: null,
            tab: null,
        });
    } catch (e) {

    }
}

export function* appSagas() {
    yield takeEvery(APP_INIT_START, appInitStartSaga);
    yield takeEvery(OPEN_MODAL, openModalSaga);
    yield takeEvery(CLOSE_MODAL, closeModalSaga);
}
