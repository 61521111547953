import { createSelector } from "reselect";

const moduleSelector = state => state.app;

export const getAppLoggedIn = createSelector(
    moduleSelector, state => state.loggedIn
);

export const getAppLoggedInProcess = createSelector(
    moduleSelector, state => state.loggedInProcess
);

export const getAppLoginError = createSelector(
    moduleSelector, state => state.loginError
);

export const getAppRegistrationError = createSelector(
    moduleSelector, state => state.registrationError
);

export const getAppPartner = createSelector(
    moduleSelector, state => state.partner
);

export const getPasswordForgotSentSelector = createSelector(
    moduleSelector, state => state.passwordForgotSent
);

export const getPasswordForgotErrorSelector = createSelector(
    moduleSelector, state => state.passwordForgotError
);

export const getPasswordResetOkSelector = createSelector(
    moduleSelector, state => state.passwordResetOk
);

export const getPasswordResetErrorSelector = createSelector(
    moduleSelector, state => state.passwordResetError
);
