import { createContext, useContext } from 'react';

export const PopupContext = createContext({});
export const usePopupContext = () => {
    const context = useContext(PopupContext);
    if (!context) {
        throw new Error('PopupContext cannot be used outside the PopupProvider')
    }
    return context;
}
