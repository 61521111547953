import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import partnerReducer from "../modules/Partner/partnerReducer.js";
import appHistory from './appHistory';
import appReducer from "./appReducer";

const rootReducers = combineReducers({
    router: connectRouter(appHistory),

    app: appReducer,
    partner: partnerReducer,
});

export default rootReducers;
