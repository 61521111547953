import { Button, Form } from "react-bootstrap";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getAppPartner } from "../Auth/authSelectors.js";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { Loader } from "../../components/Loader/Loader.jsx";
import { getPartnerUpdatingPayment } from "../Partner/partnerSelectors.js";
import { updatePartnerPaymentAction } from "../Partner/partnerActions.js";
import { PAYMENT_METHODS } from "./paymentConstants.js";
import { SectionWrapperStyled } from '../../components/Section/styled/SectionStyled';

function PartnerPayment() {
    const {getLangPath} = useLangContext();
    const dispatch = useDispatch();
    const partnerInfo = useSelector(getAppPartner);
    const updatingPayment = useSelector(getPartnerUpdatingPayment);

    const [paymentType, setPaymentType] = useState(partnerInfo.payment_type);

    useEffect(() => {
        setPaymentType(partnerInfo.payment_type);
    }, [partnerInfo.payment_type])

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(updatePartnerPaymentAction({
            payment_type: paymentType,
            payment_info: e.target.querySelector('[name=payment_info]').value,
        }));
    }, [paymentType, dispatch]);

    const handleChangePaymentType = useCallback((e) => {
        setPaymentType(e.target.value);
    }, []);

    const currentPaymentMethodConfig = useMemo(() =>
            _.find(PAYMENT_METHODS, {value: paymentType}) || {},
        [paymentType]);

    return <>
        <Loader show={updatingPayment}/>
        <SectionWrapperStyled>
            <Form onSubmit={handleSubmit}>
                <div className="d-flex flex-lg-row flex-column">
                    <Form.Group className="flex-grow-1 me-lg-3">
                        <Form.Label>{getLangPath('payment.payment_type.label')}</Form.Label>
                        <Form.Select name="payment_type" value={paymentType || ''} onChange={handleChangePaymentType}>
                            {
                                PAYMENT_METHODS.map(method => (
                                    <option key={method.name} value={method.value}>
                                        {method.name}
                                    </option>
                                ))
                            }
                        </Form.Select>
                        <Form.Text className="text-muted">{getLangPath('payment.payment_type.hint')}</Form.Text>
                    </Form.Group>

                    <Form.Group className="flex-grow-1 mt-3 mt-lg-0">
                        <Form.Label>{getLangPath('payment.payment_info.label')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="payment_info"
                            placeholder={currentPaymentMethodConfig.placeholder}
                            defaultValue={partnerInfo.payment_info || ''}
                        />
                        <Form.Text className="text-muted">{getLangPath('payment.payment_info.hint')}</Form.Text>
                    </Form.Group>
                </div>

                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex">
                        <Button variant="primary" size='md' type="submit">
                            {getLangPath('payment.save')}
                        </Button>
                    </div>
                </div>
            </Form>
        </SectionWrapperStyled>
    </>
}

export default PartnerPayment;
