function formPathWithSearchParams(params, pathname = '') {
    const urlParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([key, value]) => {
        if (value || value === 0) {
            urlParams.set(key, value);
        } else {
            urlParams.delete(key);
        }
    });

    return `${pathname || window.location.pathname}?${urlParams.toString()}`;
}

export default formPathWithSearchParams;
