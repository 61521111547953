import { createSelector } from 'reselect';

const moduleSelector = state => state.app;

export const getAppInited = createSelector(
    moduleSelector, state => state.inited,
);

export const getAvailableCountries = createSelector(
    moduleSelector, state => state.availableCountries,
);

export const getAvailableLocales = createSelector(
    moduleSelector, state => state.availableLocales,
);

export const getAppLang = createSelector(
    moduleSelector, state => state.lang,
);

export const getAppLocale = createSelector(
    moduleSelector, state => state.locale,
);

export const getOpenedModalSelector = createSelector(
    moduleSelector, state => state.openedModal,
);

export const getOpenedModalParamsSelector = createSelector(
    getOpenedModalSelector, state => state?.params,
);

export const getPartnerPostbackTypesSelector = createSelector(
    moduleSelector, state => state.postbacks_types || []
);

export const getPartnerPostbackUrlTokensSelector = createSelector(
    moduleSelector, state => state.postbacks_url_tokens || []
);
