import React, { Fragment } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { RouterPaths as RoutePaths } from "./appConstants";
import PageNotFound from "./PageNotFound.jsx";
import { useSelector } from "react-redux";
import { getAppLoggedIn, getAppLoggedInProcess } from "../modules/Auth/authSelectors.js";
import { getAppInited } from "./appSelectors";

export const getRender = (props, component, isLoggedIn, wrapper = null) => {
    const element = React.createElement(component, props);
    const wrapperElement = wrapper ? React.createElement(wrapper, props, element) : element;
    if (!isLoggedIn) {
        return wrapperElement;
    }
    return <Fragment>
        {
            wrapperElement
        }
    </Fragment>;
};

const PrivateRoute = ({ component, feature, features, mobile, wrapper, wrapperProps, ...rest }) => {
    const location = useLocation();
    const isLoggedIn = useSelector(getAppLoggedIn);
    const isLoggedInProcess = useSelector(getAppLoggedInProcess);
    const isAppInited = useSelector(getAppInited);
    if (isLoggedInProcess || !isAppInited) return null;
    if (!isLoggedIn) {
        return <Redirect to={{
            pathname: RoutePaths.login,
            search: location.search,
            state: {
                originPathname: location.pathname,
                originSearch: location.search,
                ...location.state,
            }
        }}/>;
    }
    if (feature && !features[feature]) {
        return <Route path="**" component={PageNotFound}/>
    }
    return <Route {...rest} render={(props) => (
        getRender({ ...props, ...wrapperProps }, component, isLoggedIn, wrapper)
    )}/>;
};

export default PrivateRoute;
