import moment from "moment";
import { PARTNER_STATISTICS_GROUP_TYPE } from "./statisticsConstants.js";

export const getCurrentDate = () => moment();

export const DATE_PRESETS_CONFIG = [
    {
        name: 'today',
        title: 'statistics.presets.today',
        startDate: () => moment(),
        endDate: getCurrentDate,
    },
    {
        name: 'yesterday',
        title: 'statistics.presets.yesterday',
        startDate: () => moment().subtract(1, 'days'),
        endDate: () => moment().subtract(1, 'days'),
    },
    {
        name: 'lastSevenDays',
        title: 'statistics.presets.last_seven_days',
        startDate: () => moment().subtract(7, 'days'),
        endDate: getCurrentDate,
    },
    {
        name: 'lastThirtyDays',
        title: 'statistics.presets.last_thirty_days',
        startDate: () => moment().subtract(30, 'days'),
        endDate: getCurrentDate,
    },
    {
        name: 'currentMonth',
        title: 'statistics.presets.current_month',
        startDate: () => moment().startOf('month'),
        endDate: getCurrentDate,
    },
    {
        name: 'previousMonth',
        title: 'statistics.presets.previous_month',
        startDate: () => moment().subtract(1, 'month').startOf('month'),
        endDate: () => moment().subtract(1, 'month').endOf('month'),
    },
    {
        name: 'allTime',
        title: 'statistics.presets.all_time',
        startDate: () => null,
        endDate: () => null,
    },
];

export const GROUP_CONFIG = {
    [PARTNER_STATISTICS_GROUP_TYPE.CAMPAIGN]: {
        field: 'campaign_name',
        format: value => value,
        title: 'statistics.groups.campaign',
        type: PARTNER_STATISTICS_GROUP_TYPE.CAMPAIGN,
        order: 4,
    },
    [PARTNER_STATISTICS_GROUP_TYPE.CHANNEL]: {
        field: 'campaign_channel',
        format: value => value,
        title: 'statistics.groups.channel',
        type: PARTNER_STATISTICS_GROUP_TYPE.CHANNEL,
        order: 5,
    },
    [PARTNER_STATISTICS_GROUP_TYPE.DAY]: {
        field: 'date',
        format: value => moment(value).format('DD/MM/YYYY'),
        title: 'statistics.groups.day',
        type: PARTNER_STATISTICS_GROUP_TYPE.DAY,
        order: 1,
    },
    [PARTNER_STATISTICS_GROUP_TYPE.WEEK]: {
        field: 'date',
        format: value => moment(value).format('WW/YYYY'),
        title: 'statistics.groups.week',
        type: PARTNER_STATISTICS_GROUP_TYPE.WEEK,
        order: 2,
    },
    [PARTNER_STATISTICS_GROUP_TYPE.MONTH]: {
        field: 'date',
        format: value => moment(value).format('MM/YYYY'),
        title: 'statistics.groups.month',
        type: PARTNER_STATISTICS_GROUP_TYPE.MONTH,
        order: 3,
    },
}

export const DEFAULT_FILTER_PARAMS = {
    campaign_name: '',
    campaign_channel: '',
    getStartDate: () => moment().startOf('month').toDate(),
    getEndDate: () => new Date(),
    group_type: PARTNER_STATISTICS_GROUP_TYPE.DAY,
}

export const formatStartDate = (dateObject) => moment.utc(dateObject).startOf('day').toISOString();
export const formatEndDate = (dateObject) => moment.utc(dateObject).endOf('day').toISOString();

export const formatRegisteredAtColumn = (timestamp) => {
    if (!timestamp) {
        return '-';
    }
    return moment(timestamp).format('DD/MM/YYYY');
}
