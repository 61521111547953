import styled from "styled-components";

export const PopoverArrowStyled = styled.div`
    &:after {
        border-top-color: ${({ color }) => color || 'var(--color-surface-secondary)'} !important;
        border-bottom-color: ${({ color }) => color || 'var(--color-surface-secondary)'} !important;
    }
`;

export const TooltipContentStyled = styled.div`
    background: var(--color-surface-secondary);
    padding: 16px;
    border-radius: 4px;
`;
