export const PARTNER_OFFERS_STATUS = {
    ACTIVE: 'active', // оффер активирован
    CANCELED: 'canceled', // оффер отменен компанией
    DECLINED: 'declined', // оффер отклонен пользователем
    NOT_APPLICABLE: 'not_applicable', // оффер не применим
    TIMEOUT: 'timeout', // срок действия закончился
    UPDATING: 'updating', // в ожидании принятия пользователем новых условий
    WAITING: 'waiting', // в ожидании принятия пользователем (приватный)
    WAGERED: 'wagered', // лимит оффера дотиг предела
}

export const USER_UPDATE_START = 'USER_UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const USER_UPDATE_PASSWORD_START = 'USER_UPDATE_PASSWORD_START';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAILURE = 'USER_UPDATE_PASSWORD_FAILURE';

export const LOAD_PARTNER_CAMPAIGNS_START = 'LOAD_PARTNER_CAMPAIGNS_START';
export const LOAD_PARTNER_CAMPAIGNS_SUCCESS = 'LOAD_PARTNER_CAMPAIGNS_SUCCESS';
export const LOAD_PARTNER_CAMPAIGNS_FAILURE = 'LOAD_PARTNER_CAMPAIGNS_FAILURE';

export const ADD_PARTNER_CAMPAIGN_START = 'ADD_PARTNER_CAMPAIGN_START';
export const ADD_PARTNER_CAMPAIGN_SUCCESS = 'ADD_PARTNER_CAMPAIGN_SUCCESS';
export const ADD_PARTNER_CAMPAIGN_FAILURE = 'ADD_PARTNER_CAMPAIGN_FAILURE';

export const UPDATE_PARTNER_CAMPAIGN_START = 'UPDATE_PARTNER_CAMPAIGN_START';
export const UPDATE_PARTNER_CAMPAIGN_SUCCESS = 'UPDATE_PARTNER_CAMPAIGN_SUCCESS';
export const UPDATE_PARTNER_CAMPAIGN_FAILURE = 'UPDATE_PARTNER_CAMPAIGN_FAILURE';

export const DELETE_PARTNER_CAMPAIGN_START = 'DELETE_PARTNER_CAMPAIGN_START';
export const DELETE_PARTNER_CAMPAIGN_SUCCESS = 'DELETE_PARTNER_CAMPAIGN_SUCCESS';
export const DELETE_PARTNER_CAMPAIGN_FAILURE = 'DELETE_PARTNER_CAMPAIGN_FAILURE';

export const UPDATE_PARTNER_PAYMENT_START = 'UPDATE_PARTNER_PAYMENT_START';
export const UPDATE_PARTNER_PAYMENT_SUCCESS = 'UPDATE_PARTNER_PAYMENT_SUCCESS';
export const UPDATE_PARTNER_PAYMENT_FAILURE = 'UPDATE_PARTNER_PAYMENT_FAILURE';

export const LOAD_PARTNER_STATISTICS_START = 'LOAD_PARTNER_STATISTICS_START';
export const LOAD_PARTNER_STATISTICS_SUCCESS = 'LOAD_PARTNER_STATISTICS_SUCCESS';
export const LOAD_PARTNER_STATISTICS_FAILURE = 'LOAD_PARTNER_STATISTICS_FAILURE';

export const SET_PARTNER_PARAMS = 'SET_PARTNER_PARAMS';
export const ACCEPT_PARTNER_RULES = 'ACCEPT_PARTNER_RULES';

export const LOAD_PARTNER_POSTBACKS_START = 'LOAD_PARTNER_POSTBACKS_START';
export const LOAD_PARTNER_POSTBACKS_SUCCESS = 'LOAD_PARTNER_POSTBACKS_SUCCESS';
export const LOAD_PARTNER_POSTBACKS_FAILURE = 'LOAD_PARTNER_POSTBACKS_FAILURE';

export const ADD_PARTNER_POSTBACK_START = 'ADD_PARTNER_POSTBACK_START';
export const ADD_PARTNER_POSTBACK_SUCCESS = 'ADD_PARTNER_POSTBACK_SUCCESS';
export const ADD_PARTNER_POSTBACK_FAILURE = 'ADD_PARTNER_POSTBACK_FAILURE';

export const UPDATE_PARTNER_POSTBACK_START = 'UPDATE_PARTNER_POSTBACK_START';
export const UPDATE_PARTNER_POSTBACK_SUCCESS = 'UPDATE_PARTNER_POSTBACK_SUCCESS';
export const UPDATE_PARTNER_POSTBACK_FAILURE = 'UPDATE_PARTNER_POSTBACK_FAILURE';

export const DELETE_PARTNER_POSTBACK_START = 'DELETE_PARTNER_POSTBACK_START';
export const DELETE_PARTNER_POSTBACK_SUCCESS = 'DELETE_PARTNER_POSTBACK_SUCCESS';
export const DELETE_PARTNER_POSTBACK_FAILURE = 'DELETE_PARTNER_POSTBACK_FAILURE';

export const RESET_ADD_PARTNER_POSTBACK_ERROR = 'RESET_ADD_PARTNER_POSTBACK_ERROR';
export const RESET_UPDATE_PARTNER_POSTBACK_ERROR = 'RESET_UPDATE_PARTNER_POSTBACK_ERROR';

export const REFRESH_API_KEY_START = 'REFRESH_API_KEY_START';
export const REFRESH_API_KEY_SUCCESS = 'REFRESH_API_KEY_SUCCESS';
export const REFRESH_API_KEY_FAILURE = 'REFRESH_API_KEY_FAILURE';
