import { PromoStyled } from "./styled/PromoStyled.js";
import { BinIcon } from "../../components/Icon/Icon.jsx";

function Promo() {
    return <PromoStyled>
        <a href="https://drive.google.com/drive/folders/1sxNBNsyl8LbGtzfATV9Cc8GMOJrOB3Rr" target="_blank" rel="noreferrer">Marketing Kit<BinIcon name={'IcShareArrow'}/></a>
    </PromoStyled>
}

export default Promo;
