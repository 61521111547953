import PageError from "./PageError";
import React from "react";
import { useLangContext } from "../providers/LangProvider/LangProviderContext.js";

function PageNotFound(props) {
    const {getLangPath} = useLangContext();
    return <PageError
        icon="IcEmptyPage"
        description={getLangPath('error.page_not_found_description')}
        headerText={getLangPath('error.page_not_found_title')}
        linkText={getLangPath('error.go_back')}
        {...props}
    />
}

export default PageNotFound;
