import createAction from "../../app/createAction.js";
import {
    ACCEPT_PARTNER_RULES,
    ADD_PARTNER_CAMPAIGN_FAILURE,
    ADD_PARTNER_CAMPAIGN_START,
    ADD_PARTNER_CAMPAIGN_SUCCESS,
    ADD_PARTNER_POSTBACK_FAILURE,
    ADD_PARTNER_POSTBACK_START,
    ADD_PARTNER_POSTBACK_SUCCESS,
    DELETE_PARTNER_CAMPAIGN_FAILURE,
    DELETE_PARTNER_CAMPAIGN_START,
    DELETE_PARTNER_CAMPAIGN_SUCCESS,
    DELETE_PARTNER_POSTBACK_FAILURE,
    DELETE_PARTNER_POSTBACK_START,
    DELETE_PARTNER_POSTBACK_SUCCESS,
    LOAD_PARTNER_CAMPAIGNS_FAILURE,
    LOAD_PARTNER_CAMPAIGNS_START,
    LOAD_PARTNER_CAMPAIGNS_SUCCESS,
    LOAD_PARTNER_POSTBACKS_FAILURE,
    LOAD_PARTNER_POSTBACKS_START,
    LOAD_PARTNER_POSTBACKS_SUCCESS,
    LOAD_PARTNER_STATISTICS_FAILURE,
    LOAD_PARTNER_STATISTICS_START,
    LOAD_PARTNER_STATISTICS_SUCCESS,
    REFRESH_API_KEY_FAILURE,
    REFRESH_API_KEY_START,
    REFRESH_API_KEY_SUCCESS,
    RESET_ADD_PARTNER_POSTBACK_ERROR,
    RESET_UPDATE_PARTNER_POSTBACK_ERROR,
    UPDATE_PARTNER_CAMPAIGN_FAILURE,
    UPDATE_PARTNER_CAMPAIGN_START,
    UPDATE_PARTNER_CAMPAIGN_SUCCESS,
    UPDATE_PARTNER_PAYMENT_FAILURE,
    UPDATE_PARTNER_PAYMENT_START,
    UPDATE_PARTNER_PAYMENT_SUCCESS,
    UPDATE_PARTNER_POSTBACK_FAILURE,
    UPDATE_PARTNER_POSTBACK_START,
    UPDATE_PARTNER_POSTBACK_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_UPDATE_PASSWORD_FAILURE,
    USER_UPDATE_PASSWORD_START,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_START,
    USER_UPDATE_SUCCESS
} from "./partnerConstants.js";

export const userUpdateAction = (payload) => createAction(USER_UPDATE_START, payload);
export const userUpdateSuccess = (payload) => createAction(USER_UPDATE_SUCCESS, payload);
export const userUpdateFailure = (payload) => createAction(USER_UPDATE_FAILURE, payload);

export const userUpdatePasswordAction = (payload) => createAction(USER_UPDATE_PASSWORD_START, payload);
export const userUpdatePasswordSuccess = (payload) => createAction(USER_UPDATE_PASSWORD_SUCCESS, payload);
export const userUpdatePasswordFailure = (payload) => createAction(USER_UPDATE_PASSWORD_FAILURE, payload);

export const loadPartnerCampaignsAction = (payload) => createAction(LOAD_PARTNER_CAMPAIGNS_START, payload);
export const loadPartnerCampaignsSuccess = (payload) => createAction(LOAD_PARTNER_CAMPAIGNS_SUCCESS, payload);
export const loadPartnerCampaignsFailure = (payload) => createAction(LOAD_PARTNER_CAMPAIGNS_FAILURE, payload);

export const addPartnerCampaignAction = (payload) => createAction(ADD_PARTNER_CAMPAIGN_START, payload);
export const addPartnerCampaignSuccess = (payload) => createAction(ADD_PARTNER_CAMPAIGN_SUCCESS, payload);
export const addPartnerCampaignFailure = (payload) => createAction(ADD_PARTNER_CAMPAIGN_FAILURE, payload);

export const updatePartnerCampaignAction = (payload) => createAction(UPDATE_PARTNER_CAMPAIGN_START, payload);
export const updatePartnerCampaignSuccess = (payload) => createAction(UPDATE_PARTNER_CAMPAIGN_SUCCESS, payload);
export const updatePartnerCampaignFailure = (payload) => createAction(UPDATE_PARTNER_CAMPAIGN_FAILURE, payload);

export const deletePartnerCampaignAction = (payload) => createAction(DELETE_PARTNER_CAMPAIGN_START, payload);
export const deletePartnerCampaignSuccess = (payload) => createAction(DELETE_PARTNER_CAMPAIGN_SUCCESS, payload);
export const deletePartnerCampaignFailure = (payload) => createAction(DELETE_PARTNER_CAMPAIGN_FAILURE, payload);

export const updatePartnerPaymentAction = (payload) => createAction(UPDATE_PARTNER_PAYMENT_START, payload);
export const updatePartnerPaymentSuccess = (payload) => createAction(UPDATE_PARTNER_PAYMENT_SUCCESS, payload);
export const updatePartnerPaymentFailure = (payload) => createAction(UPDATE_PARTNER_PAYMENT_FAILURE, payload);

export const loadPartnerStatisticsAction = (payload) => createAction(LOAD_PARTNER_STATISTICS_START, payload);
export const loadPartnerStatisticsSuccess = (payload) => createAction(LOAD_PARTNER_STATISTICS_SUCCESS, payload);
export const loadPartnerStatisticsFailure = (payload) => createAction(LOAD_PARTNER_STATISTICS_FAILURE, payload);

export const acceptPartnerRulesAction = (payload) => createAction(ACCEPT_PARTNER_RULES, payload);

export const loadPartnerPostbacksAction = (payload) => createAction(LOAD_PARTNER_POSTBACKS_START, payload);
export const loadPartnerPostbacksSuccess = (payload) => createAction(LOAD_PARTNER_POSTBACKS_SUCCESS, payload);
export const loadPartnerPostbacksFailure = (payload) => createAction(LOAD_PARTNER_POSTBACKS_FAILURE, payload);

export const addPartnerPostbackAction = (payload) => createAction(ADD_PARTNER_POSTBACK_START, payload);
export const addPartnerPostbackSuccess = (payload) => createAction(ADD_PARTNER_POSTBACK_SUCCESS, payload);
export const addPartnerPostbackFailure = (payload) => createAction(ADD_PARTNER_POSTBACK_FAILURE, payload);

export const updatePartnerPostbackAction = (payload) => createAction(UPDATE_PARTNER_POSTBACK_START, payload);
export const updatePartnerPostbackSuccess = (payload) => createAction(UPDATE_PARTNER_POSTBACK_SUCCESS, payload);
export const updatePartnerPostbackFailure = (payload) => createAction(UPDATE_PARTNER_POSTBACK_FAILURE, payload);

export const deletePartnerPostbackAction = (payload) => createAction(DELETE_PARTNER_POSTBACK_START, payload);
export const deletePartnerPostbackSuccess = (payload) => createAction(DELETE_PARTNER_POSTBACK_SUCCESS, payload);
export const deletePartnerPostbackFailure = (payload) => createAction(DELETE_PARTNER_POSTBACK_FAILURE, payload);

export const resetAddPartnerPostbackErrorAction = (payload) => createAction(RESET_ADD_PARTNER_POSTBACK_ERROR, payload);
export const resetUpdatePartnerPostbackErrorAction = (payload) => createAction(RESET_UPDATE_PARTNER_POSTBACK_ERROR, payload);

export const refreshApiKeyAction = (payload) => createAction(REFRESH_API_KEY_START, payload);
export const refreshApiKeySuccess = (payload) => createAction(REFRESH_API_KEY_SUCCESS, payload);
export const refreshApiKeyFailure = (payload) => createAction(REFRESH_API_KEY_FAILURE, payload);
