import { useLangContext } from '../../providers/LangProvider/LangProviderContext.js';
import _ from 'lodash';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnerCampaignsByOfferId } from '../Partner/partnerSelectors.js';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { loadPartnerCampaignsAction } from '../Partner/partnerActions.js';
import { CampaignsHeaderStyled, CampaignsStyled, CampaignsTableStyled } from './styled/CampaignsStyled';
import OfferReferralLink from '../Offers/OfferReferralLink';
import { CAMPAIGNS_MODALS } from './campaignsConstants';
import CampaignAddModal from './CampaignAddModal';
import CampaignUpdateModal from './CampaignUpdateModal';
import { useResponsive } from "../../components/responsive/index.js";

function Campaigns(props) {
    const { offerInfo } = props;
    const { isLargeMinWidth } = useResponsive();
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();
    const campaignsByOfferId = useSelector(getPartnerCampaignsByOfferId);
    const campaignsList = useMemo(() => campaignsByOfferId[offerInfo.offer_id] || [], [campaignsByOfferId, offerInfo]);
    const [openedModal, setOpenedModal] = useState({});

    useEffect(() => {
        dispatch(loadPartnerCampaignsAction(offerInfo.offer_id));
    }, [dispatch, offerInfo]);

    const handleAddNewClick = useCallback((e) => {
        e.stopPropagation();
        setOpenedModal({
            name: CAMPAIGNS_MODALS.CAMPAIGN_ADD,
            params: { offerInfo },
        });
    }, [offerInfo]);

    const handleEditButtonClick = useCallback((e) => {
        const campaignId = e.currentTarget.dataset.id;
        const campaign = _.find(campaignsList, { id: +campaignId });
        setOpenedModal({
            name: CAMPAIGNS_MODALS.CAMPAIGN_UPDATE,
            params: {
                campaignId: campaign.id,
                campaignName: campaign.campaign_name,
                campaignChannel: campaign.campaign_channel,
                offerInfo: offerInfo,
            },
        });
    }, [campaignsList, offerInfo]);

    const handleCloseModal = useCallback(() => {
        setOpenedModal({});
    }, []);

    return <>
        <CampaignsStyled>
            <Accordion defaultActiveKey={'0'}>
                <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>
                        <CampaignsHeaderStyled>
                            <h3 className={'mb-0'}>{getLangPath('campaigns.title')}</h3>
                            <Button variant={'info'} as={'div'}
                                    onClick={handleAddNewClick}>
                                {getLangPath('campaigns.add_new')}
                            </Button>
                        </CampaignsHeaderStyled>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CampaignsTableStyled>
                            <thead>
                            <tr>
                                <th className={'name'}>{getLangPath('campaigns.campaign_name')}</th>
                                <th className={'channel'}>{getLangPath('campaigns.campaign_channel')}</th>
                                <th className={'link'}>{getLangPath('campaigns.referral_link')}</th>
                                <th className={'control'}>{null}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                _.sortBy(campaignsList, 'campaign_name').map((campaign) => {
                                    return (
                                        <Fragment key={campaign.id}>
                                            <tr>
                                                <td className={'name'}>{campaign.campaign_name}</td>
                                                <td className={'channel'}>{campaign.campaign_channel}</td>
                                                <td className={'link'}>
                                                    <OfferReferralLink
                                                        campaign={campaign.campaign_name}
                                                        channel={campaign.campaign_channel}
                                                        offerInfo={offerInfo}
                                                    />
                                                </td>
                                                <td className={'control'}>
                                                    <Button
                                                        data-id={campaign.id}
                                                        onClick={handleEditButtonClick}
                                                        variant='outline'
                                                        className={'edit'}
                                                    >
                                                        {getLangPath('campaigns.edit')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                })
                            }
                            </tbody>
                        </CampaignsTableStyled>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </CampaignsStyled>
        {
            openedModal.name === CAMPAIGNS_MODALS.CAMPAIGN_ADD &&
            <Modal show
                   onHide={handleCloseModal}
                   centered
                   size={'lg'}
            >
                <CampaignAddModal params={openedModal.params} closeModal={handleCloseModal}/>
            </Modal>
        }
        {
            openedModal.name === CAMPAIGNS_MODALS.CAMPAIGN_UPDATE &&
            <Modal show
                   onHide={handleCloseModal}
                   centered
                   size={'lg'}
            >
                <CampaignUpdateModal params={openedModal.params} closeModal={handleCloseModal}/>
            </Modal>
        }

    </>;
}

export default Campaigns;
