import { createGlobalStyle } from "styled-components";

export const GlobalButtonsStyled = createGlobalStyle`
    .btn {
        font-weight: 600;
        font-size: 14px;

        border-radius: 0;
        border-color: transparent;

        &.color-white {
            color: var(--color-white);
        }
    }

    .btn-group-sm > .btn,
    .btn-sm {
        font-weight: 600;
        font-size: 14px;

        border-radius: 2px;
        padding: 6px 16px;
    }

    .btn-group-md > .btn,
    .btn-md {
        font-weight: 600;
        font-size: 14px;

        border-radius: 12px;
        padding: 8px 24px;
    }

    .btn-group-lg > .btn,
    .btn-lg {
        font-size: 16px;
        font-weight: 600;

        border-radius: 12px;
        padding: 12px 32px;
    }

    .btn-primary {
        background: var(--color-yellow);
        border: 1px solid var(--color-yellow);
        border-radius: 12px;
        color: #0A0A10;

        &:disabled {
            color: #0A0A10;
            background: var(--color-yellow-dark);
            border: 1px solid var(--color-yellow-dark);
        }

        &:focus {
            color: #0A0A10;
            background: var(--color-yellow);
            border: 1px solid var(--color-yellow);
            box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
        }

        &:hover {
            color: #0A0A10;
            background: var(--color-yellow-light);
            border: 1px solid var(--color-yellow-light);
        }

        &:active {
            color: #0A0A10;
            background: var(--color-yellow);
            border: 1px solid var(--color-yellow);
        }
    }

    .btn-outline {
        color: var(--color-white-60);
        border-radius: 12px;
        
        &:hover {
            color: var(--color-white-95);
        }
    }

    .btn-outline-primary {
        border: 2px solid var(--color-yellow);
        box-sizing: border-box;
        border-radius: 0;
        color: var(--color-yellow);
        background: transparent;

        &:hover {
            color: var(--color-yellow-light);
            background: transparent;
            border: 2px solid var(--color-yellow-light);
        }

        &.active,
        &:active {
            color: var(--color-yellow);
            background: transparent;
            border: 2px solid var(--color-yellow);
        }
    }

    .btn-outline-danger {
        color: var(--color-negative);
        background: transparent;
        border-color: transparent;

        &:hover {
            color: var(--color-negative-lite);
            background: transparent;
            border-color: transparent;
        }

        &.active,
        &:active {
            color: var(--color-negative-lite);
            background: transparent;
            border-color: transparent;
        }
    }

    .btn-link {
        color: var(--color-yellow);
        text-decoration: none;

        &:hover,
        &:focus {
            color: var(--color-white);
            text-decoration: underline;
        }
    }

    .btn-outline-info {
        color: #fff;

        &:hover {
            color: #fff;
            background: #393C5D;
            text-decoration: none;
            border-color: #393C5D;
        }

        &.active,
        &:active {
            color: #64A7FF;
            background: #393C5D;
            border-color: #393C5D;
        }
        &:disabled {
            color: var(--text-color-secondary);
        }
        &.negative {
            color: var(--color-negative);
        }
    }

    // Tertiary
    .btn-info {
        color: var(--color-white);
        background: #2F3350;
        border: 1px solid #2F3350;
        border-radius: 12px;

        &:focus {
            color: var(--color-white);
            background: #393C5D;
            border: 1px solid #393C5D;
            box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
        }

        &:hover {
            color: var(--color-white);
            background: var(--color-purple-highlight);
            border: 1px solid var(--color-purple-highlight);
        }

        &:active, &.active {
            color: var(--color-white);
            background: var(--color-purple-highlight);
            border: 1px solid var(--color-purple-highlight);
        }

        &:disabled {
            color: var(--text-color-secondary);
            background: #30334F;
            border: 1px solid #30334F;
        }
    }

    .btn-outline-warning {
        color: var(--color-yellow);

        svg {
            transition: fill .15s ease-in-out;
            fill: var(--color-yellow);
        }

        &:hover {
            background-color: var(--color-yellow);
            border-color: var(--color-yellow);

            svg {
                fill: var(--color-black);
            }
        }

        &:active {
            svg {
                fill: var(--color-black);
            }
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgb(255 221 60 / 50%);
        }
    }
`