import { Link, Redirect } from "react-router-dom";
import { RouterPaths } from "../../../app/appConstants";
import { Button, Form } from "react-bootstrap";
import { Loader } from "../../../components/Loader/Loader.jsx";
import { connect } from "react-redux";
import { loginAction } from "../authActions.js";
import { getAppLoggedIn, getAppLoggedInProcess, getAppLoginError } from "../authSelectors.js";
import { useCallback } from "react";
import { LoginForgotPasswordLinkContainerStyled, LoginStyled } from "./styled/LoginStyled.js";
import FormPasswordEye from "../../../components/FormPasswordEye/FormPasswordEye.jsx";
import { createStructuredSelector } from "reselect";
import { useLangContext } from "../../../providers/LangProvider/LangProviderContext.js";
import AuthWrapper from "./AuthWrapper.jsx";

function Login(props) {
    const {
        loggedIn,
        loggedInProcess,
        loginError,

        loginAction,
    } = props;
    const { getLangPath } = useLangContext();

    const submitHandler = useCallback((e) => {
        e.preventDefault();
        loginAction({
            login: e.target.querySelector('[name=email]').value,
            password: e.target.querySelector('[name=password]').value,
        });
    }, [loginAction]);

    if (loggedIn) {
        return <Redirect to={RouterPaths.main}/>
    }

    return <AuthWrapper>
        <LoginStyled>
            <Loader show={loggedInProcess}/>

            <Form.Group className="mb-5 mt-2">
                <h4 className="text-center">{getLangPath('auth.title_login')}</h4>
            </Form.Group>
            <Form onSubmit={submitHandler}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Control name="email" type="email" placeholder={getLangPath('auth.field_login')}
                                  isInvalid={loginError}
                                  required/>
                </Form.Group>

                <Form.Group className="mb-2" controlId="formBasicPassword">
                    <FormPasswordEye name="password" placeholder={getLangPath('auth.field_password')}
                                     isInvalid={loginError} required/>
                    {
                        loginError && <>
                            <Form.Control.Feedback type="invalid">
                                {getLangPath(loginError)}
                            </Form.Control.Feedback>
                        </>
                    }
                </Form.Group>
                <LoginForgotPasswordLinkContainerStyled>
                    <Link to={RouterPaths.forgot}>
                        {getLangPath('auth.link_forgot_password')}
                    </Link>
                </LoginForgotPasswordLinkContainerStyled>

                <div className="d-grid mb-2">
                    <Button variant={'primary'} size={'lg'} type="submit" className="mb-4">
                        {getLangPath('auth.login_submit')}
                    </Button>
                    <Button variant={'outline-info'} size={'md'} as={Link} to={RouterPaths.register} type="button">
                        {getLangPath('auth.link_to_register')}
                    </Button>
                </div>
            </Form>
        </LoginStyled>
    </AuthWrapper>
}

const mapStateToProps = createStructuredSelector({
    loggedIn: getAppLoggedIn,
    loggedInProcess: getAppLoggedInProcess,
    loginError: getAppLoginError,
});

const mapDispatchToProps = (dispatch) => ({
    loginAction: (payload) => dispatch(loginAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
