import { createContext, useContext } from "react";

export const LangProviderContext = createContext({});

export const useLangContext = () => {
    const context = useContext(LangProviderContext);
    if (!context) {
        throw new Error('LangContext cannot be used outside the LangContext provider')
    }
    return context;
}
