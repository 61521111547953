import _ from "lodash";
import { createSelector } from "reselect";

const moduleSelector = state => state.partner;

export const getPartnerCampaignsByOfferId = createSelector(
    moduleSelector, state => state.campaignsByOfferId
);

export const getPartnerStatistics = createSelector(
    moduleSelector, state => state.statistics
);

export const getPartnerUpdatingPayment = createSelector(
    moduleSelector, state => state.updatingPayment
);

export const getPartnerIsStatisticsLoading = createSelector(
    moduleSelector, state => state.isStatisticsLoading
);

export const getPartnerStatisticsTotal = createSelector(
    getPartnerStatistics, statistics => _.reduce(statistics, (sum, item) => ({
        registered: sum.registered + +item.registered,
        ftd: sum.ftd + +item.ftd,
        turnover: sum.turnover + +item.turnover,
        profit: sum.profit + +item.profit,
    }), { registered: 0, ftd: 0, turnover: 0, profit: 0 })
);

export const getPartnerPostbacksSelector = createSelector(
    moduleSelector, state => state.postbacks || {}
);

export const getPostbacksByOfferIdSelector = (offerId) => createSelector(
    getPartnerPostbacksSelector, state => {
        return state[offerId] || [];
    },
);

export const getPartnerAddPostbackErrorSelector = createSelector(
    moduleSelector, state => state.addPostbackError || '',
);

export const getPartnerUpdatePostbackErrorSelector = createSelector(
    moduleSelector, state => state.updatePostbackError || '',
);

export const getPartnerIsAddPostbackLoadingSelector = createSelector(
    moduleSelector, state => state.isAddPostbackLoading || false,
);

export const getPartnerIsUpdatePostbackLoadingSelector = createSelector(
    moduleSelector, state => state.isUpdatePostbackLoading || false,
);

export const getPartnerIsRefreshApiKeyLoadingSelector = createSelector(
    moduleSelector, state => state.isRefreshApiKeyLoading || false,
);
export const getUserUpdateErrorSelector = createSelector(
    moduleSelector, state => state.userUpdateError,
);
export const getUserUpdatePasswordErrorSelector = createSelector(
    moduleSelector, state => state.userUpdatePasswordError,
);
