import styled from "styled-components";

export const PopupStyled = styled.div`
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    margin-right: 8px;
    
    > div {
        margin-bottom: 8px;
    }
`;
