import { Button, Form } from 'react-bootstrap';
import { useCallback, useState } from 'react';
import { useLangContext } from '../../providers/LangProvider/LangProviderContext.js';
import { CampaignFormControlPanelStyled, CampaignFormWrapperStyled } from './styled/CampaignFormStyled.js';
import OfferReferralLink from '../Offers/OfferReferralLink';
import { useResponsive } from "../../components/responsive/index.js";

export const CampaignForm = (props) => {
    const {
        channelDefaultValue,
        nameDefaultValue,
        offerInfo,
        onCancel,
        onDelete,
        onSubmit,
    } = props;
    const { getLangPath } = useLangContext();
    const { isLargeMinWidth } = useResponsive();
    const [campaignName, setCampaignName] = useState(nameDefaultValue || '');
    const [campaignChannel, setCampaignChannel] = useState(channelDefaultValue || '');

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        onSubmit({
            campaign_name: campaignName,
            campaign_channel: campaignChannel,
        });
    }, [campaignChannel, campaignName, onSubmit]);

    const handleChangeCampaignChannel = useCallback((e) => {
        setCampaignChannel(e.currentTarget.value);
    }, []);

    const handleChangeCampaignName = useCallback((e) => {
        setCampaignName(e.currentTarget.value);
    }, []);

    return <CampaignFormWrapperStyled>
        <Form onSubmit={handleSubmit} className='p-3'>
            <div className='d-flex flex-lg-row flex-column'>
                <Form.Group className='flex-grow-1 me-lg-3'>
                    <Form.Label>{getLangPath('campaigns.campaign_name')}</Form.Label>
                    <Form.Control
                        type='text'
                        name='campaign_name'
                        placeholder={getLangPath('campaigns.campaign_name')}
                        value={campaignName}
                        required
                        onChange={handleChangeCampaignName}
                    />
                </Form.Group>

                <Form.Group className='flex-grow-1 mt-3 mt-lg-0'>
                    <Form.Label>{getLangPath('campaigns.campaign_channel')}</Form.Label>
                    <Form.Control
                        type='text'
                        name='campaign_channel'
                        placeholder={getLangPath('campaigns.campaign_channel')}
                        onChange={handleChangeCampaignChannel}
                        value={campaignChannel}
                    />
                </Form.Group>
            </div>
            <div className='d-flex align-items-center'>
                <Form.Label className='mt-3 me-3'>{getLangPath('campaigns.referral_link')}</Form.Label>
            </div>

            <OfferReferralLink offerInfo={offerInfo} campaign={campaignName} channel={campaignChannel}/>

            <CampaignFormControlPanelStyled className='mt-3'>
                <div>
                    <Button variant='primary' size={isLargeMinWidth ? 'lg' : 'sm'} type='submit'>
                        {getLangPath('campaigns.save')}
                    </Button>
                    <Button variant='outline' size={isLargeMinWidth ? 'lg' : 'sm'} onClick={onCancel} className='ms-3'>
                        {getLangPath('campaigns.cancel')}
                    </Button>
                </div>
                {
                    !!onDelete &&
                    <Button variant='outline-danger' size={isLargeMinWidth ? 'lg' : 'sm'} onClick={onDelete}
                            className='ms-3'>
                        {getLangPath('campaigns.delete_campaign')}
                    </Button>
                }
            </CampaignFormControlPanelStyled>
        </Form>
    </CampaignFormWrapperStyled>;
};
