import CopyReferralLink from '../../components/CopyReferralLink/CopyReferralLink';
import { useMemo } from 'react';
import { formReferralLink } from '../Partner/partnerUtils';
import { useSelector } from 'react-redux';
import { getAppPartner } from '../Auth/authSelectors';
import { OfferReferralLinkStyled } from './styled/OfferReferralLinkStyled';

function OfferReferralLink(props) {
    const { campaign, channel, offerInfo } = props;
    const partnerInfo = useSelector(getAppPartner);

    const referralLink = useMemo(() => {
        if (offerInfo && partnerInfo) {
            return formReferralLink({
                campaign,
                channel,
                offerId: offerInfo.offer_id,
                refshare: partnerInfo.partner_uid,
                url: offerInfo.target_url,
            });
        }
    }, [campaign, channel, offerInfo, partnerInfo]);

    return  <OfferReferralLinkStyled className={'offer-referral-link'}>
        <span>{referralLink}</span>
        <CopyReferralLink referralLink={referralLink} className={'copy-button'} iconSize={'16px'} />
    </OfferReferralLinkStyled>
}

export default OfferReferralLink;
