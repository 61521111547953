import React from "react";
import RoutePage from "./app/RoutePage";
import { GlobalStyles } from "./modules/GlobalStyles/GlobalStyles";
import Header from "./modules/Header/Header.jsx";
import Modals from "./modules/Modals/Modals.jsx";
import PopupProvider from "./modules/Popup/PopupProvider";
import LangProvider from "./providers/LangProvider/LangProvider.jsx";

function App() {
    return <>
        <GlobalStyles/>
        <LangProvider> {/* app init here */}
            <PopupProvider>
                <Header/>
                <div style={{ margin: '0 16px' }}>
                    <Modals/>
                    <RoutePage/>
                </div>
            </PopupProvider>
        </LangProvider>
    </>;
}

export default App;
