import { GlobalStyled } from "./styled/GlobalStyled";
import { GlobalFormsStyled } from "./styled/GlobalFormsStyled.js";
import { GlobalButtonsStyled } from "./styled/GlobalButtonsStyled.js";
import { GlobalOverlaysStyled } from "./styled/GlobalOverlaysStyled.js";
import { GlobalModalStyled } from "./styled/GlobalModalStyled.js";
import { GlobalUtilsStyled } from "./styled/GlobalUtilsStyled.js";
import { GlobalAccordionStyled } from "./styled/GlobalAccordionStyled.js";

export const GlobalStyles = () => (
    <>
        <GlobalStyled/>
        <GlobalAccordionStyled/>
        <GlobalFormsStyled/>
        <GlobalButtonsStyled/>
        <GlobalOverlaysStyled/>
        <GlobalModalStyled/>
        <GlobalUtilsStyled/>
    </>
);
