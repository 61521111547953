import createAction from '../../app/createAction.js';
import {
    CONFIRM_EMAIL_FAILURE,
    CONFIRM_EMAIL_START,
    CONFIRM_EMAIL_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_START,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_START,
    LOGOUT_SUCCESS,
    REGISTRATION_FAILURE,
    REGISTRATION_START,
    REGISTRATION_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS
} from './authConstants.js';

export const loginAction = (payload) => createAction(LOGIN_START, payload);
export const loginSuccess = (payload) => createAction(LOGIN_SUCCESS, payload);
export const loginFailure = (payload) => createAction(LOGIN_FAILURE, payload);

export const logoutAction = (payload) => createAction(LOGOUT_START, payload);
export const logoutSuccess = (payload) => createAction(LOGOUT_SUCCESS, payload);
export const logoutFailure = (payload) => createAction(LOGOUT_FAILURE, payload);

export const registrationAction = (payload) => createAction(REGISTRATION_START, payload);
export const registrationSuccess = (payload) => createAction(REGISTRATION_SUCCESS, payload);
export const registrationFailure = (payload) => createAction(REGISTRATION_FAILURE, payload);

export const forgotPasswordAction = (payload) => createAction(FORGOT_PASSWORD_START, payload);
export const forgotPasswordSuccess = (payload) => createAction(FORGOT_PASSWORD_SUCCESS, payload);
export const forgotPasswordFailure = (payload) => createAction(FORGOT_PASSWORD_FAILURE, payload);

export const resetPasswordAction = (payload) => createAction(RESET_PASSWORD_START, payload);
export const resetPasswordSuccess = (payload) => createAction(RESET_PASSWORD_SUCCESS, payload);
export const resetPasswordFailure = (payload) => createAction(RESET_PASSWORD_FAILURE, payload);

export const confirmEmailAction = (payload) => createAction(CONFIRM_EMAIL_START, payload);
export const confirmEmailSuccess = (payload) => createAction(CONFIRM_EMAIL_SUCCESS, payload);
export const confirmEmailFailure = (payload) => createAction(CONFIRM_EMAIL_FAILURE, payload);
