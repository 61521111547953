import React from 'react';
import { Route, Switch } from 'react-router';
import { RouterPaths } from './appConstants';
import PageWelcome from "../pages/PageWelcome/PageWelcome.jsx";
import PageLogin from "../pages/PageLogin/PageLogin.jsx";
import PageRegister from "../pages/PageRegister/PageRegister.jsx";
import PageStatistics from "../pages/PageStatistics/PageStatistics.jsx";
import PagePayment from "../pages/PagePayment/PagePayment.jsx";
import PageSupport from "../pages/PageSupport/PageSupport.jsx";
import PageProfile from "../pages/PageProfile/PageProfile.jsx";
import PageForgot from "../pages/PageForgot/PageForgot.jsx";
import PageReset from "../pages/PageReset/PageReset.jsx";
import PageOffers from '../pages/PageOffers/PageOffers';
import PageConfirm from '../pages/PageConfirm/PageConfirm';
import PagePromo from "../pages/PagePromo/PagePromo.jsx";
import PageNotFound from "./PageNotFound.jsx";
import PrivateRoute from "./PrivateRoute.jsx";

const RoutePage = () => {
    return <>
        <Switch>
            <Route exact path={RouterPaths.confirm} component={PageConfirm}/>
            <Route exact path={RouterPaths.forgot} component={PageForgot}/>
            <Route exact path={RouterPaths.login} component={PageLogin}/>
            <Route exact path={RouterPaths.main} component={PageWelcome}/>
            <Route exact path={RouterPaths.register} component={PageRegister}/>
            <Route exact path={RouterPaths.reset} component={PageReset}/>
            <Route path={RouterPaths.help} component={PageSupport}/>
            <PrivateRoute path={RouterPaths.offers} component={PageOffers}/>
            <PrivateRoute path={RouterPaths.payouts} component={PagePayment}/>
            <PrivateRoute path={RouterPaths.promo} component={PagePromo}/>
            <PrivateRoute path={RouterPaths.settings} component={PageProfile}/>
            <PrivateRoute path={RouterPaths.statistics} component={PageStatistics}/>
            <Route path="**" component={PageNotFound}/>
        </Switch>
    </>;
};

export default RoutePage;
