import { call, put, takeEvery } from "redux-saga/effects";
import { appInitAction, closeModalAction } from "../../app/appActions.js";
import { appInitStartSaga } from "../../app/appSagas";
import { failurePut } from "../../app/failurePut";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../app/request";
import {
    addPartnerCampaignFailure,
    addPartnerCampaignSuccess,
    addPartnerPostbackFailure,
    addPartnerPostbackSuccess,
    deletePartnerCampaignFailure,
    deletePartnerCampaignSuccess,
    deletePartnerPostbackFailure,
    deletePartnerPostbackSuccess,
    loadPartnerCampaignsFailure,
    loadPartnerCampaignsSuccess, loadPartnerPostbacksAction,
    loadPartnerPostbacksFailure,
    loadPartnerPostbacksSuccess,
    loadPartnerStatisticsFailure,
    loadPartnerStatisticsSuccess,
    refreshApiKeyFailure,
    refreshApiKeySuccess,
    updatePartnerCampaignFailure,
    updatePartnerCampaignSuccess,
    updatePartnerPaymentFailure,
    updatePartnerPaymentSuccess,
    updatePartnerPostbackFailure,
    updatePartnerPostbackSuccess,
    userUpdateFailure,
    userUpdatePasswordFailure,
    userUpdatePasswordSuccess,
    userUpdateSuccess,
} from "./partnerActions";
import {
    ACCEPT_PARTNER_RULES,
    ADD_PARTNER_CAMPAIGN_START,
    ADD_PARTNER_POSTBACK_START,
    DELETE_PARTNER_CAMPAIGN_START,
    DELETE_PARTNER_POSTBACK_START,
    LOAD_PARTNER_CAMPAIGNS_START,
    LOAD_PARTNER_POSTBACKS_START,
    LOAD_PARTNER_STATISTICS_START,
    REFRESH_API_KEY_START,
    UPDATE_PARTNER_CAMPAIGN_START,
    UPDATE_PARTNER_PAYMENT_START,
    UPDATE_PARTNER_POSTBACK_START,
    USER_UPDATE_PASSWORD_START,
    USER_UPDATE_START,
} from "./partnerConstants";

export function* loadPartnerCampaignsSaga({ payload }) {
    try {
        const data = yield call(getRequest, `/partner/offers/${payload}/campaigns/list`);
        yield put(loadPartnerCampaignsSuccess({ campaignsList: data, offerId: payload }));
    } catch (e) {
        yield call(failurePut, e, loadPartnerCampaignsFailure)
    }
}

export function* addPartnerCampaignSaga({ payload }) {
    try {
        const campaign = yield call(postRequest, `/partner/offers/${payload.offerId}/campaigns`, payload.payload);
        yield put(addPartnerCampaignSuccess(campaign));
    } catch (e) {
        yield call(failurePut, e, addPartnerCampaignFailure);
    }
}

export function* updatePartnerCampaignSaga({ payload }) {
    try {
        const campaign = yield call(putRequest, `/partner/offers/${payload.offerId}/campaigns/${payload.campaignId}`, payload.payload);
        yield put(updatePartnerCampaignSuccess(campaign));
    } catch (e) {
        yield call(failurePut, e, updatePartnerCampaignFailure);
    }
}

export function* deletePartnerCampaignSaga({ payload }) {
    try {
        yield call(deleteRequest, `/partner/offers/${payload.offerId}/campaigns/${payload.campaignId}`);
        yield put(deletePartnerCampaignSuccess(payload));
    } catch (e) {
        yield call(failurePut, e, deletePartnerCampaignFailure);
    }
}

export function* updatePartnerPaymentSaga({ payload }) {
    try {
        yield call(putRequest, '/partner/payment', payload);
        yield put(updatePartnerPaymentSuccess(payload));

        yield put(appInitAction()); // reload params
    } catch (e) {
        yield call(failurePut, e, updatePartnerPaymentFailure);
    }
}

export function* acceptPartnerRulesSaga({ payload }) {
    try {
        yield call(postRequest, '/partner/accept', payload);
    } catch (e) {
    }
    yield put(appInitAction()); // reload params
}

export function* loadPartnerStatisticsSaga({ payload }) {
    try {
        const data = yield call(postRequest, '/partner/statistics', payload);
        yield put(loadPartnerStatisticsSuccess(data));
    } catch (e) {
        yield call(failurePut, e, loadPartnerStatisticsFailure);
    }
}

export function* loadPartnerPostbacksSaga({ payload }) {
    try {
        const data = yield call(getRequest, `/partner/offers/${payload.offer_id}/postbacks/list`);
        yield put(loadPartnerPostbacksSuccess({
            offer_id: payload.offer_id,
            postbacks: data,
        }));
    } catch (e) {
        yield call(failurePut, e, loadPartnerPostbacksFailure);
    }
}

export function* addPartnerPostbackSaga({ payload }) {
    try {
        const postback = yield call(postRequest, `/partner/offers/${payload.offer_id}/postbacks`, payload);
        yield put(addPartnerPostbackSuccess(postback));
        yield put(closeModalAction());
        yield put(loadPartnerPostbacksAction({
            offer_id: payload.offer_id,
        }));
    } catch (e) {
        yield call(failurePut, e, addPartnerPostbackFailure);
    }
}

export function* updatePartnerPostbackSaga({ payload }) {
    try {
        const postback = yield call(putRequest, `/partner/offers/${payload.offer_id}/postbacks/${payload.postback_id}`, payload);
        yield put(updatePartnerPostbackSuccess(postback));
        yield put(closeModalAction());
        yield put(loadPartnerPostbacksAction({
            offer_id: payload.offer_id,
        }));
    } catch (e) {
        yield call(failurePut, e, updatePartnerPostbackFailure);
    }
}

export function* deletePartnerPostbackSaga({ payload }) {
    try {
        yield call(deleteRequest, `/partner/offers/${payload.offer_id}/postbacks/${payload.postback_id}`);
        yield put(deletePartnerPostbackSuccess(payload));
    } catch (e) {
        yield call(failurePut, e, deletePartnerPostbackFailure);
    }
}

export function* refreshApiKeySaga() {
    try {
        yield call(postRequest, '/partner/api-key/refresh');
        yield call(appInitStartSaga);
        yield put(refreshApiKeySuccess());
    } catch (e) {
        yield call(failurePut, e, refreshApiKeyFailure);
    }
}

export function* userUpdateSaga({ payload }) {
    try {
        yield call(postRequest, '/partner/profile', payload);
        yield call(appInitStartSaga);
        yield put(userUpdateSuccess());
    } catch (e) {
        yield call(failurePut, e, userUpdateFailure);
    }
}

export function* userUpdatePasswordSaga({ payload }) {
    try {
        yield call(postRequest, '/partner/password', payload);
        yield put(userUpdatePasswordSuccess());
    } catch (e) {
        yield call(failurePut, e, userUpdatePasswordFailure);
    }
}

export function* partnerSagas() {
    yield takeEvery(ADD_PARTNER_CAMPAIGN_START, addPartnerCampaignSaga);
    yield takeEvery(DELETE_PARTNER_CAMPAIGN_START, deletePartnerCampaignSaga);
    yield takeEvery(LOAD_PARTNER_CAMPAIGNS_START, loadPartnerCampaignsSaga);
    yield takeEvery(LOAD_PARTNER_STATISTICS_START, loadPartnerStatisticsSaga);
    yield takeEvery(UPDATE_PARTNER_CAMPAIGN_START, updatePartnerCampaignSaga);
    yield takeEvery(UPDATE_PARTNER_PAYMENT_START, updatePartnerPaymentSaga);
    yield takeEvery(ACCEPT_PARTNER_RULES, acceptPartnerRulesSaga);
    yield takeEvery(LOAD_PARTNER_POSTBACKS_START, loadPartnerPostbacksSaga);
    yield takeEvery(ADD_PARTNER_POSTBACK_START, addPartnerPostbackSaga);
    yield takeEvery(UPDATE_PARTNER_POSTBACK_START, updatePartnerPostbackSaga);
    yield takeEvery(DELETE_PARTNER_POSTBACK_START, deletePartnerPostbackSaga);
    yield takeEvery(REFRESH_API_KEY_START, refreshApiKeySaga);
    yield takeEvery(USER_UPDATE_START, userUpdateSaga);
    yield takeEvery(USER_UPDATE_PASSWORD_START, userUpdatePasswordSaga);
}