import { memo } from 'react';
import { ReactComponent as IcEyeOn } from '../../assets/icons/ic_eye_on.svg';
import { ReactComponent as IcEyeOff } from '../../assets/icons/ic_eye_off.svg';
import { ReactComponent as IcDocCopy } from '../../assets/icons/ic_doc_copy.svg';
import { ReactComponent as IcPencil } from "../../assets/icons/ic_pencil.svg";
import { ReactComponent as IcXMark } from '../../assets/icons/ic_x_mark.svg';
import { ReactComponent as IcChevronDown } from "../../assets/icons/ic_chevron_down.svg";
import { ReactComponent as IcCloseWhite } from '../../assets/icons/ic_close_white.svg';
import { ReactComponent as IcInbox } from '../../assets/icons/ic_inbox.svg';
import { ReactComponent as IcLineChartUp } from '../../assets/icons/ic_line_chart_up.svg';
import { ReactComponent as IcCoinsStacked } from '../../assets/icons/ic_coins_stacked.svg';
import { ReactComponent as IcMessageQuestionCircle } from '../../assets/icons/ic_message_question_circle.svg';
import { ReactComponent as IcSettings } from '../../assets/icons/ic_settings.svg';
import { ReactComponent as IcFlipBackward } from '../../assets/icons/ic_flip_backward.svg';
import { ReactComponent as IcLogOut } from '../../assets/icons/ic_log_out.svg';
import { ReactComponent as IcMenuBurger } from '../../assets/icons/ic_menu_burger.svg';
import { ReactComponent as IcPersonGroup } from '../../assets/icons/ic_person_group.svg';
import { ReactComponent as IcShareArrow } from '../../assets/icons/ic_share_arrow.svg';

const iconsComponents = {
    IcChevronDown,
    IcCloseWhite,
    IcCoinsStacked,
    IcDocCopy,
    IcEyeOff,
    IcEyeOn,
    IcFlipBackward,
    IcInbox,
    IcLineChartUp,
    IcLogOut,
    IcMenuBurger,
    IcMessageQuestionCircle,
    IcPencil,
    IcPersonGroup,
    IcSettings,
    IcShareArrow,
    IcXMark,
}

export const Icon = memo(({
    name,
    height = "1em",
    width = "1em",
    size,
    asComponent,
    style = {},
    defaultIcon,
    ...iconProps
}) => {
    if (!name) {
        return null;
    }
    if (asComponent && !iconsComponents[name]) {
        if (defaultIcon && iconsComponents[defaultIcon]) {
            name = defaultIcon;
        } else {
            return <></>
        }
    }
    const Component = iconsComponents[name];
    return asComponent
            ? <Component height={height} width={width} {...iconProps}
                         style={{fontSize: size || '1.5em', display: 'inline-block', lineHeight: 1.25, ...style}}/>
            : <img src={`${process.env.PUBLIC_URL}/assets/${name}.svg`} alt={name} height={height}
                   width={width} {...iconProps}/>;
});

export const BinIcon = memo((props) => {
    return <Icon asComponent {...props}/>
});
