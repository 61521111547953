import { RouterPaths } from '../../app/appConstants';

export const MENU_ITEMS = [
    {
        name: 'offers',
        path: RouterPaths.offers,
        icon: 'IcInbox',
    },
    {
        name: 'statistics',
        path: RouterPaths.statistics,
        icon: 'IcLineChartUp',
    },
    {
        name: 'promo',
        path: RouterPaths.promo,
        icon: 'IcPersonGroup',
    },
    {
        name: 'payouts',
        path: RouterPaths.payouts,
        icon: 'IcCoinsStacked',
    },
    {
        name: 'settings',
        path: RouterPaths.settings,
        icon: 'IcSettings',
    },
    {
        name: 'help',
        path: RouterPaths.help,
        icon: 'IcMessageQuestionCircle',
    }
];
