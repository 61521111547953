import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import appHistory from './appHistory';
// Read token
import './token.js';

const loggerMiddleware = ({ getState }) => next => action => {
    const result = next(action);

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.info('[Dispatching]', action, getState());
    }

    return result;
};

const systemWatchers = ({ dispatch }) => {
    // TODO ios not working
    // window.screen.orientation.addEventListener("change", () => windowScreenOrientation(dispatch));
    // windowScreenOrientation(dispatch);

    // window.addEventListener('resize', () => windowResizeHandler(dispatch));
    // windowResizeHandler(dispatch);

    return next => action => next(action);
};

// const windowResizeHandler = debounce(dispatch => {
//     dispatch({
//         type: 'WINDOW_RESIZE',
//         payload: {
//             width: window.innerWidth,
//             height: window.innerHeight,
//         },
//     });
// }, 250);

export const sagaMiddleware = createSagaMiddleware();

const reactRouterMiddleware = routerMiddleware(appHistory);

const middlewares = [
    reactRouterMiddleware,
    systemWatchers,
    sagaMiddleware,
    loggerMiddleware,
];

export default middlewares;
