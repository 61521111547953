import { call, put, takeEvery } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import { appInitAction } from "../../app/appActions.js";
import { RouterPaths } from "../../app/appConstants";
import appHistory from "../../app/appHistory.js";
import { failurePut } from "../../app/failurePut.js";
import { postRequest } from "../../app/request.js";
import {
    confirmEmailFailure,
    confirmEmailSuccess,
    forgotPasswordFailure,
    forgotPasswordSuccess,
    loginFailure,
    loginSuccess,
    logoutFailure,
    logoutSuccess,
    registrationFailure,
    registrationSuccess,
    resetPasswordFailure,
    resetPasswordSuccess
} from "./authActions.js";
import {
    CONFIRM_EMAIL_START,
    FORGOT_PASSWORD_START,
    LOGIN_START,
    LOGOUT_START,
    REGISTRATION_START,
    RESET_PASSWORD_START
} from "./authConstants.js";

const browserCookies = new Cookies();

export function updateCookiesOnAuthSaga({ payload, cookies = browserCookies }) {
    cookies.set('token', payload.token, { path: '/', maxAge: 60 * 60 * 24 * 365 });
}

export function* loginStartSaga({ payload }) {
    try {
        const data = yield call(postRequest, '/auth/login', payload);
        yield call(updateCookiesOnAuthSaga, { payload: { token: data.token, secret: data.secret } });
        yield put(loginSuccess(data.token));
        yield put(appInitAction());
    } catch (e) {
        yield call(failurePut, e, loginFailure);
    }
}

export function* logoutStartSaga({ payload }) {
    try {
        yield call(postRequest, '/auth/logout');
    } catch (e) {
        yield call(failurePut, e, logoutFailure);
    } finally {
        browserCookies.remove('token', { path: '/' });
        yield put(logoutSuccess());
        appHistory.push(RouterPaths.login);
    }
}

export function* registrationStartSaga({ payload }) {
    try {
        const data = yield call(postRequest, '/auth/register', payload);
        yield call(updateCookiesOnAuthSaga, { payload: { token: data.token, secret: data.secret } });
        yield put(registrationSuccess(data.token));
        yield put(appInitAction());
    } catch (e) {
        yield call(failurePut, e, registrationFailure);
    }
}

export function* forgotPasswordSaga({ payload }) {
    try {
        yield call(postRequest, '/auth/forgot', payload);
        yield put(forgotPasswordSuccess());
    } catch (e) {
        yield call(failurePut, e, forgotPasswordFailure);
    }
}

export function* resetPasswordSaga({ payload }) {
    try {
        yield call(postRequest, '/auth/reset', payload);
        if (!payload.check) {
            yield put(resetPasswordSuccess());
        }
    } catch (e) {
        if (payload.check) {
            appHistory.push(RouterPaths.login);
        }
        yield call(failurePut, e, resetPasswordFailure);
    }
}

export function* confirmEmailSaga({ payload }) {
    try {
        yield call(postRequest, '/auth/confirm', payload);
        yield put(confirmEmailSuccess());
    } catch (e) {
        yield call(failurePut, e, confirmEmailFailure);
    } finally {
        appHistory.push(RouterPaths.main);
    }
}

export function* authSagas() {
    yield takeEvery(LOGIN_START, loginStartSaga);
    yield takeEvery(LOGOUT_START, logoutStartSaga);
    yield takeEvery(REGISTRATION_START, registrationStartSaga);
    yield takeEvery(FORGOT_PASSWORD_START, forgotPasswordSaga);
    yield takeEvery(RESET_PASSWORD_START, resetPasswordSaga);
    yield takeEvery(CONFIRM_EMAIL_START, confirmEmailSaga);
}
