import { Button, Form } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useCallback, useState } from "react";
import { PARTNER_POSTBACK_FORM_TYPES } from "./partnerPostbacksConstants";
import { checkIsPostbackUrlValid } from "./partnerPostbacksUtils";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { Loader } from "../../components/Loader/Loader.jsx";
import { getPartnerPostbackTypesSelector, getPartnerPostbackUrlTokensSelector } from "../../app/appSelectors.js";

function PartnerPostbackForm(props) {
    const {
        formType = PARTNER_POSTBACK_FORM_TYPES.ADD,
        item = {},
        onCancel,
        offerId,
        onSubmit,
        postbackTypes,
        postbackUrlTokens,
        campaignId,
        isLoading,
        error,
        resetError,
    } = props;
    const {getLangPath} = useLangContext();
    const [postbackUrl, setPostbackUrl] = useState(item.url || '');

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        onSubmit({
            campaign_id: campaignId,
            offer_id: offerId,
            postback_id: item.id,
            type: e.target.querySelector('[name=type]').value,
            url: e.target.querySelector('[name=url]').value,
        });
    }, [campaignId, item, offerId, onSubmit]);

    const handleUrlTokenClick = useCallback((e) => {
        const tokenValue = e.currentTarget.dataset.token;
        if (postbackUrl.includes(tokenValue)) {
            setPostbackUrl(currentPostbackUrl => currentPostbackUrl.replace(tokenValue, ''));
        } else {
            setPostbackUrl(currentPostbackUrl => `${currentPostbackUrl}${tokenValue}`);
        }
    }, [postbackUrl]);

    const handlePostbackUrlChange = useCallback((e) => {
        const value = e.currentTarget.value;
        if (error === 'error.postback_url_not_valid') {
            if (checkIsPostbackUrlValid(value) && resetError) {
                resetError();
            }
        }
        setPostbackUrl(value);
    }, [error, resetError]);

    return <>
        <Loader show={isLoading}/>
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>{getLangPath('postbacks.type')}</Form.Label>
                <Form.Select name="type" defaultValue={item.type}>
                    {
                        postbackTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mt-3">
                <Form.Label>{getLangPath('postbacks.url')}</Form.Label>
                <Form.Control
                    isInvalid={!!error}
                    required
                    as="textarea"
                    name="url"
                    value={postbackUrl}
                    onChange={handlePostbackUrlChange}
                />
                {
                    !!error && <>
                        <Form.Control.Feedback type="invalid">
                            {getLangPath(error)}
                        </Form.Control.Feedback>
                    </>
                }
            </Form.Group>
            <div className="mt-3">
                <Form.Label>{getLangPath('postbacks.available_url_tokens')}</Form.Label>
                <div className="d-flex flex-wrap gap-2">
                    {
                        postbackUrlTokens.map((token) => {
                            const tokenValue = `{${token}}`;
                            return (
                                <Button variant="info"
                                        key={token}
                                        size="sm"
                                        active={postbackUrl.includes(tokenValue)}
                                        data-token={tokenValue}
                                        onClick={handleUrlTokenClick}
                                >
                                    {tokenValue}
                                </Button>
                            );
                        })
                    }
                </div>
            </div>
            <div className="d-flex gap-3 mt-5">
                <Button variant="primary" size='md' type="submit">
                    {getLangPath(`postbacks.form.${formType}.save_button`)}
                </Button>
                <Button variant="info" size='md' onClick={onCancel}>
                    {getLangPath('postbacks.cancel')}
                </Button>
            </div>
        </Form>
    </>
}

const mapStateToProps = createStructuredSelector({
    postbackTypes: getPartnerPostbackTypesSelector,
    postbackUrlTokens: getPartnerPostbackUrlTokensSelector,
})

export default connect(mapStateToProps)(PartnerPostbackForm);
