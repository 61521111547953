import { createGlobalStyle } from 'styled-components';

export const GlobalAccordionStyled = createGlobalStyle`
    .accordion {
        border: 1px solid var(--color-surface-secondary);
        border-radius: 16px;
        :hover {
            border-color: var(--color-secondary-highlight);
        }
    }
    
    .accordion-button {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        
        background-color: var(--color-surface-secondary);
        border-radius: 16px;
        border: none;
        color: var(--color-white);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 20px 24px;
        &::after, &:not(.collapsed)::after {
            background-image: url("/assets/icons/accordion-arrow-down.svg");
            margin-left: 16px;
        }

        &:not(.collapsed) {
            color: var(--color-white);
            background-color: transparent;
        }
        &, &:focus, &:not(.collapsed) {
            box-shadow: none;
        }
    }

    .accordion-item {
        border-radius: 16px;
        background-color: transparent;
        border: none;
        color: var(--color-white);
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
    .accordion-item:last-of-type .accordion-collapse {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
    .accordion-body {
        padding: 0 24px 24px;
    }
`;