import styled from 'styled-components';
import { BREAKPOINTS } from "../../../app/appConstants";

export const MenuStyled = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    padding: 0 8px;
    flex-wrap: wrap;

    @media(max-width: ${BREAKPOINTS.large}px) {
        gap: 12px;
    }
    @media(max-width: ${BREAKPOINTS.medium}px) {
        gap: 4px;
    }
`;

export const MenuItemStyled = styled.div`
    a {
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 400;
        color: var(--color-white-60);
        text-decoration: none;
        line-height: 24px;
        padding-top: 10px;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
    }

    svg {
        fill: var(--color-white-60);
        margin-right: 6px;
    }

    &.active, &:hover {
        a {
            color: var(--color-white-95);
            border-bottom-color: var(--color-white-95);
        }
        
        svg {
            fill: var(--color-white-95);
        }
    }
`;
