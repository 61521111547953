export const PAYMENT_METHODS = [
    {
        name: '-',
        placeholder: '',
        value: '',
    },
    {
        name: 'Advcash EUR',
        placeholder: 'E123456789012',
        value: 'advcash_eur',
    },
    {
        name: 'Advcash UAH',
        placeholder: 'H123456789012',
        value: 'advcash_uah',
    },
    {
        name: 'Advcash USD',
        placeholder: 'U123456789012',
        value: 'advcash_usd',
    },
    {
        name: 'Bitcoin',
        placeholder: '16Sy8mvjyNgCRYS14m1Rtca3UfrFPzz9eJ',
        value: 'btc',
    },
    {
        name: 'Brazil Bank Transfer',
        placeholder: '0123456789123, Bank code, Bank branch, Beneficiary name, CPF (tax_id)',
        value: 'transfero',
    },
    {
        name: 'Doku IDR',
        placeholder: '1234567890, Account holder name',
        value: 'doku_idr',
    },
    {
        name: 'Dana',
        placeholder: '+62855746705744, Account holder name, City',
        value: 'dana',
    },
    {
        name: 'GoPay',
        placeholder: '+62855746705744, Account holder name, City',
        value: 'go_pay',
    },
    {
        name: 'LinkAja',
        placeholder: '+62855746705744, Account holder name, City',
        value: 'linkaja',
    },
    {
        name: 'Litecoin',
        placeholder: 'LaKJTdakSmKM17SphYhpB4dcMy6HWiMBZv',
        value: 'ltc',
    },
    {
        name: 'Neteller',
        placeholder: 'name@example.com',
        value: 'neteller',
    },
    {
        name: 'Ovo',
        placeholder: '+62855746705744, Account holder name, City',
        value: 'ovo',
    },
    {
        name: 'Skrill',
        placeholder: 'name@example.com',
        value: 'skrill',
    },
    {
        name: 'Papara',
        placeholder: '2069568720, Date of birth (31.12.2020), First name, Last name',
        value: 'papara',
    },
    {
        name: 'ShopeePay',
        placeholder: '+62855746705744, Account holder name, City',
        value: 'shopee_pay',
    },
    {
        name: 'YooMoney',
        placeholder: '4100116075156746',
        value: 'yma',
    },
    {
        name: 'USDT (ERC20)',
        placeholder: '0x9A6eE84AEb83ec999F68fADa4A8115570E433Ac9',
        value: 'erc20',
    },
    {
        name: 'USDT (TRC20)',
        placeholder: 'TLWm3BKbQtpSJMviKTD6vzoW9ZmvhYZiuB',
        value: 'trc20',
    },
    {
        name: 'Webmoney Z',
        placeholder: 'Z123456789012',
        value: 'wmz',
    },
]
