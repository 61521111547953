import { useSelector } from "react-redux";
import { getAppLoggedIn } from "../../modules/Auth/authSelectors.js";
import { Redirect } from "react-router-dom";
import { RouterPaths } from "../../app/appConstants";
import { generatePath } from "react-router";

function PageWelcome() {
    const isLoggedIn = useSelector(getAppLoggedIn);

    if (isLoggedIn) {
        return <Redirect to={generatePath(RouterPaths.offers)}/>
    } else {
        return <Redirect to={generatePath(RouterPaths.login)}/>
    }

    // return <div className={'m-3'}>
    //     <div>Affilers - The best of the best</div>
    // </div>
}

export default PageWelcome;
