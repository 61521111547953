import { createGlobalStyle } from "styled-components";

export const GlobalUtilsStyled = createGlobalStyle`
    .text-left {
        text-align: left;
    }    
    
    .text-right {
        text-align: right;
    }

    .text-secondary {
        color: var(--text-color-secondary) !important;
    }

    .color-white-60 {
        color: var(--color-white-60) !important;
    }
    
    .flex-1 {
        flex: 1;
    }
    
    .mt-2rem {
        margin-top: 2rem !important;
    }
    
    .color-red {
        color: var(--color-red) !important;
    }
    
    .f14,
    .font-size-14 {
      font-size: 14px;
    }
    
    .f16,
    .font-size-16 {
      font-size: 16px;
    }

    .f20,
    .font-size-20 {
        font-size: 20px;
    }

    .f24,
    .font-size-24 {
        font-size: 24px;
    }

    .f28,
    .font-size-28 {
        font-size: 28px;
    }
    
    .fw400 {
        font-weight: 400;
    }

    .fw500 {
        font-weight: 500;
    }
    
    .fw600 {
        font-weight: 600;
    }
    
    .fw700 {
        font-weight: 700;
    }
    
    .visibility-hidden {
        visibility: hidden;
    }
    
    .react-financial-charts-x-axis {
        z-index: 0;
    }
    
    .cursor-pointer {
        cursor: pointer;
    }
`;
