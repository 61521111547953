import { useLangContext } from "../../../providers/LangProvider/LangProviderContext";
import { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RouterPaths } from "../../../app/appConstants";
import ButtonCooldown from "../../../components/ButtonCooldown/ButtonCooldown.jsx";
import AuthWrapper from "./AuthWrapper.jsx";
import { LoginStyled } from "./styled/LoginStyled.js";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordAction, resetPasswordAction } from "../authActions.js";
import { getPasswordForgotErrorSelector, getPasswordForgotSentSelector } from "../authSelectors.js";

function Forgot() {
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const passwordForgotSent = useSelector(getPasswordForgotSentSelector);
    const passwordForgotError = useSelector(getPasswordForgotErrorSelector);

    const submitHandler = useCallback((e) => {
        e.preventDefault();
        const email = e.target.querySelector('[name=email]').value;
        dispatch(forgotPasswordAction({ email }));
        setEmail(email);
    }, [dispatch]);

    const resendClickHandler = useCallback((e) => {
        e.preventDefault();
        dispatch(forgotPasswordAction({ email }));
    }, [email, dispatch]);

    return <AuthWrapper>
        <LoginStyled>
            {
                passwordForgotSent ? <>
                    <Form.Group className="mb-5 mt-2">
                        <h4 className="text-center">{getLangPath('auth.reset_password.title_sent')}</h4>
                    </Form.Group>
                    <p className="mb-4">{getLangPath('auth.reset_password.follow_link')}</p>
                    <div className="d-flex flex-column mb-2">
                        <ButtonCooldown successMessage={getLangPath('auth.reset_password.sent_email')}
                                        cooldownMessage={getLangPath('auth.reset_password.resend_in')}
                                        className="mb-4"
                                        size={'lg'}
                                        onClick={resendClickHandler}>
                            {getLangPath('auth.reset_password.resend_email')}
                        </ButtonCooldown>
                        <Button variant={'outline-info'} size={'md'} as={Link} to={RouterPaths.login} type="button">
                            {getLangPath('auth.reset_password.back_to_login_button')}
                        </Button>
                    </div>
                </> : <>
                    <Form.Group className="mb-4 mt-2">
                        <h4 className="text-center">{getLangPath('auth.reset_password.title_forgot')}</h4>
                    </Form.Group>
                    <p>{getLangPath('auth.reset_password.enter_email')}</p>
                    <Form onSubmit={submitHandler}>
                        <Form.Group className={'mb-4'}>
                            <Form.Control name="email" type="email"
                                          placeholder={getLangPath('auth.reset_password.email_placeholder')}
                                          required/>
                        </Form.Group>

                        {
                            !!passwordForgotError && <>
                                <p className={'text-danger'}>
                                    {getLangPath(passwordForgotError)}
                                </p>
                            </>
                        }

                        <div className="d-grid mb-2">
                            <Button variant={'primary'} size={'lg'} type="submit" className="mb-4">
                                {getLangPath('auth.reset_password.submit_button')}
                            </Button>
                            <Button variant={'outline-info'} size={'md'} as={Link} to={RouterPaths.login} type="button">
                                {getLangPath('auth.reset_password.back_to_login_button')}
                            </Button>
                        </div>
                    </Form>
                </>
            }
        </LoginStyled>
    </AuthWrapper>
}

export default Forgot;
