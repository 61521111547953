import styled from 'styled-components';

export const CampaignsStyled = styled.div`
    background: var(--color-surface);
    border-top: 1px solid var(--color-base-secondary);
    .accordion, .accordion-button {
        background: none;
        border: none;
    }
    .accordion-button {
        padding: 16px;
    }
    .accordion-body {
        padding: 0 16px 16px;
    }
`;

export const CampaignsHeaderStyled = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    
    h3 {
        font-size: 16px;
        font-weight: 600;
    }
    
    .btn-info {
        padding: 8px 16px;
    }
`;

export const CampaignsTableStyled = styled.table`
    width: 100%;
    background: var(--color-surface-secondary);
    border-radius: 8px;
    overflow: hidden;
    
    tr {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 16px;
    }
    
    tbody tr {
        border-top: 1px solid var(--color-surface);
    }
    
    th {
        font-weight: 400;
        color: var(--color-white-60);
    }
    
    .name {
        flex-basis: 20%;
    }
    .channel {
        flex-basis: 20%;
    }
    .link {
        flex-basis: 54%;
        .copy-button {
            margin-left: 8px;
        }
    }
    .control {
        flex-basis: 6%;
        display: flex;
        justify-content: flex-end;
    }
    
    .edit {
        color: var(--color-accent);
        margin-right: -16px;
    }
`;
