import styled from 'styled-components';

export const PageErrorIconContainerStyled = styled.div`
    background-color: #FF646C1A;
    border-radius: 4px;
    padding: 8px;
`;

export const PageErrorLinkStyled = styled.a`
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    &, &:hover {
        color: var(--color-white);
    }
`
