import { createGlobalStyle } from 'styled-components';

export const GlobalStyled = createGlobalStyle`
    :root {
        --color-base: #131420;
        --color-base-secondary: #1B1C2D;
        --color-main: #42456C;
        --color-surface: #262840;
        --color-surface-highlight: #393C60;
        --color-surface-secondary: #31324E;
        --color-yellow: #FFDD3C;
        --color-yellow-light: #FFEFA3;
        --color-yellow-dark: #A38600;
        --color-white: #FFFFFF;
        --color-white-60: rgba(255, 255, 255, 0.6);
        --color-white-95: rgba(255, 255, 255, 0.95);
        --color-purple: #393C5D;
        --color-purple-highlight: #5F649B;
        --color-green: #54EEAB;
        --color-green-secondary: #14C679;
        --color-red: #FF858B;
        --color-red-secondary: #D05257;
        --color-black: #2A2B30;
        --color-secondary: #BDC0DB;
        --color-secondary-active: #7D86B2;
        --color-secondary-highlight: #B9BFDF;
        --color-positive: #54EEAB;
        --color-negative: #FF858B;
        --color-accent: #FFDD3C;
        --color-link: #3275F5;
        --color-negative-lite: #FF999E;
        --text-color-secondary: #A3A6CC;
        --text-color-dark: #0A0A10;
        --app-bg-color: #131420;
        --app-bg-header-color: #1E1F30;
        --app-bg-modal-color: var(--color-surface);
        --app-bg-modal-header-color: var(--color-surface-secondary);
        --app-bg-modal-panel-color: var(--color-surface-secondary);
        --dx-bg-color: var(--color-surface-secondary);
        --brand-color: #FFDD3C;
        --icon-primary-color: #CAD0E7;
        --background-base: #0A0A10;
        --background-neutral-10: rgba(129, 146, 228, 0.1);
        --background-neutral-20: rgba(129, 146, 228, 0.2);
    }

    html, body {
        height: 100%;
    }

    #root {
        height: 100%;
    }

    body {
        font-family: "OpenSans", sans-serif;
        font-size: 16px;
        font-weight: 300;

        background: var(--color-base);

        color: white;
        margin: 0;
        padding: 0;
    }
`;
