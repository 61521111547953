import { Offcanvas } from "react-bootstrap";
import { createContext, useCallback, useContext, useEffect } from "react";
import { useSlider } from "./sliderHooks";
import {
    SliderIconWrapperStyled,
    SliderRowItemStyled,
    SliderToggleComponentWrapperStyled
} from "./styled/SliderStyled";
import classNames from "classnames";
import { BinIcon } from "../Icon/Icon.jsx";

const SliderContext = createContext({});
export const useSliderContext = () => {
    const context = useContext(SliderContext);
    if (!context) {
        throw new Error('SliderContext cannot be used outside the SliderContext provider')
    }
    return context;
}

export const SliderCloseButton = () => {
    const {closeSlider} = useSliderContext();
    return <SliderIconWrapperStyled onClick={closeSlider}>
        <BinIcon name="IcCloseWhite" height="24px" width="24px"/>
    </SliderIconWrapperStyled>
}

export const Slider = ({
    children,
    headerContentComponent,
    onClose,
    onEscapeKeyDown,
    placement = 'end',
    show = false,
    showCloseButton = true,
    style,
    toggleComponent,
    toggleWrapperClassname,
}) => {
    const {closeSlider, isShowSlider, openSlider} = useSlider();

    useEffect(() => {
        if (show && !isShowSlider) {
            openSlider();
        }
    }, [isShowSlider, openSlider, show]);

    const closeSliderHandler = useCallback(() => {
        if (onClose) {
            onClose();
        }
        closeSlider();
    }, [closeSlider, onClose]);

    return <SliderContext.Provider value={{closeSlider, closeSliderHandler, openSlider, isShowSlider}}>
        {!!toggleComponent &&
            <SliderToggleComponentWrapperStyled onClick={openSlider} className={toggleWrapperClassname}>
                {toggleComponent}
            </SliderToggleComponentWrapperStyled>}
        <Offcanvas show={isShowSlider} onHide={closeSliderHandler} placement={placement}
                   style={{width: "100%", ...style}}>
            {
                (showCloseButton || headerContentComponent) && <div className="offcanvas-header">
                    {showCloseButton && <SliderCloseButton/>}
                    {headerContentComponent}
                </div>
            }
            {children}
        </Offcanvas>
    </SliderContext.Provider>
}

const SliderRow = ({children, onClick, disabled, className, ...props}) => {
    const {closeSlider} = useSliderContext();
    const handleClick = useCallback((event) => {
        closeSlider();
        if (onClick) {
            onClick(event);
        }
    }, [closeSlider, onClick])
    return <SliderRowItemStyled
        onClick={handleClick}
        className={classNames(className, {'disabled': disabled})}
        {...props}
    >
        {children}
    </SliderRowItemStyled>
}

const SliderCloseWrapper = ({children}) => {
    const {closeSlider} = useSliderContext();

    const handleSliderClick = useCallback(() => {
        closeSlider();
    }, [closeSlider]);

    return <div onClick={handleSliderClick}>
        {children}
    </div>
}

Slider.Row = SliderRow;
Slider.CloseButton = SliderCloseButton;
Slider.CloseWrapper = SliderCloseWrapper;

