import { useMediaQuery } from "@mui/material";
import { BREAKPOINTS } from "../../app/appConstants";

export const useMinWidthMediaQuery = (breakpoint) => useMediaQuery(`(min-width: ${breakpoint}px)`);
export const useMaxWidthMediaQuery = (breakpoint) => useMediaQuery(`(max-width: ${breakpoint}px)`);
export const useMinHeightMediaQuery = (breakpoint) => useMediaQuery(`(min-height: ${breakpoint}px)`);
export const useMaxHeightMediaQuery = (breakpoint) => useMediaQuery(`(max-height: ${breakpoint}px)`);

export const useResponsive = () => {
    const isSmallMinWidth = useMinWidthMediaQuery(BREAKPOINTS.small);
    const isMediumMinWidth = useMinWidthMediaQuery(BREAKPOINTS.medium);
    const isLargeMinWidth = useMinWidthMediaQuery(BREAKPOINTS.large);
    const isExtraLargeMinWidth = useMinWidthMediaQuery(BREAKPOINTS.extraLarge);
    const isExtraExtraLargeMinWidth = useMinWidthMediaQuery(BREAKPOINTS.extraExtraLarge);

    const isSmallMaxWidth = useMaxWidthMediaQuery(BREAKPOINTS.small);
    const isMediumMaxWidth = useMaxWidthMediaQuery(BREAKPOINTS.medium);
    const isLargeMaxWidth = useMaxWidthMediaQuery(BREAKPOINTS.large);
    const isExtraLargeMaxWidth = useMaxWidthMediaQuery(BREAKPOINTS.extraLarge);
    const isExtraExtraLargeMaxWidth = useMaxWidthMediaQuery(BREAKPOINTS.extraExtraLarge);
    return {
        isSmallMaxWidth,
        isSmallMinWidth,
        isMediumMaxWidth,
        isMediumMinWidth,
        isLargeMaxWidth,
        isLargeMinWidth,
        isExtraLargeMaxWidth,
        isExtraLargeMinWidth,
        isExtraExtraLargeMaxWidth,
        isExtraExtraLargeMinWidth
    }
}