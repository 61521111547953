import { CampaignForm } from './CampaignForm';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { loadPartnerCampaignsAction } from '../Partner/partnerActions';
import { ModalInformationStyled } from '../Modals/styled/ModalsStyled';
import { useLangContext } from '../../providers/LangProvider/LangProviderContext';
import { usePopupContext } from '../Popup/PopupContext';
import { postRequest } from '../../app/request';

function CampaignAddModal(props) {
    const { closeModal, params } = props;
    const dispatch = useDispatch();
    const { getLangPath } = useLangContext();
    const { catchPopup } = usePopupContext();

    useEffect(() => {
        if (!params?.offerInfo) {
            closeModal();
        }
    }, [closeModal, params]);

    const addCampaignFormSubmit = useCallback((campaignParams) => {
        postRequest(`/partner/offers/${params.offerInfo.offer_id}/campaigns`, campaignParams)
            .then(() => {
                closeModal();
                dispatch(loadPartnerCampaignsAction(params.offerInfo.offer_id));
            })
            .catch(e => {
                catchPopup(e.response);
            });
    }, [catchPopup, closeModal, dispatch, params]);

    const addCampaignFormCancel = useCallback(() => {
        closeModal();
    }, [closeModal]);

    if (!params?.offerInfo) {
        return null;
    }

    return <ModalInformationStyled>
        <Modal.Header closeButton>
            {getLangPath('campaigns.add_campaign')}
        </Modal.Header>
        <CampaignForm
            offerInfo={params?.offerInfo}
            onSubmit={addCampaignFormSubmit}
            onCancel={addCampaignFormCancel}
        />
    </ModalInformationStyled>;
}

export default CampaignAddModal;
