import styled from 'styled-components';

export const OfferInfoStyled = styled.div`
    border-radius: 8px;
    overflow: hidden;
`;

export const OfferInfoContentStyled = styled.div`
    padding: 16px;
    background: var(--color-surface);
    display: flex;
    flex-direction: column;
`;

export const OfferInfoContentHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    h2 {
        font-weight: 600;
        font-size: 20px;
    }
`;

export const OfferInfoContentTitleContainerStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const OfferInfoTypeStyled = styled.div`
    display: inline-flex;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 0 4px;
    background: var(--background-neutral-20);
    border-radius: 4px;

    &.has-updating {
        color: var(--color-white-60);
        position: relative;
        display: inline-block;

        &:after {
            display: block;
            content: '';
            width: calc(100% - 0.5rem);
            border-bottom: 1px solid var(--color-secondary);
            bottom: 0.6rem;
            position: absolute;
        }
    }
`;

export const OfferInfoStatusStyled = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-accent);
    font-size: 12px;
    font-weight: 400;
    
    .indicator {
        background: var(--color-accent);
    }
    
    &.positive {
        color: var(--color-positive);
        
        .indicator {
            background: var(--color-positive);
        }
    }
    &.negative {
        color: var(--color-negative);
        
        .indicator {
            background: var(--color-negative);
        }
    }
`;

export const OfferInfoStatusIndicatorStyled = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin-left: 8px;
`;

export const OfferInfoConditionsStyled = styled.div`
    margin-top: 16px;
    
    th {
        font-weight: 400;
        color: var(--color-white-60);
    }
    td {
        font-weight: 600;
        color: var(--color-white-95);
        padding-left: 2rem;
    }
    
    .has-updating {
        color: var(--color-white-60);
        position: relative;
        display: inline-block;
        
        &:after {
            display: block;
            content: '';
            width: calc(100% - 2rem);
            border-bottom: 1px solid var(--color-secondary);
            bottom: 0.7rem;
            position: absolute;
        }
    }
`;

export const OfferInfoReferralLinkStyled = styled.div`
    margin-top: 16px;
    
    .offer-referral-link {
        padding: 8px;
        border-radius: 8px;
        background: var(--color-base-secondary);
    }
`;

export const OfferInfoControlPanelStyled = styled.div`
    padding: 16px;
    background: var(--color-base-secondary);
`;
