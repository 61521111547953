import { Button } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";

const formatSeconds = (secondsNumber) => secondsNumber < 10 ? `0${secondsNumber}` : secondsNumber;

export const ButtonCooldown = (props) => {
    const {
        children,
        successMessage,
        cooldownMessage,
        onClick,
        time = 30,
        ...buttonProps
    } = props;
    const [cooldown, setCooldown] = useState(time);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        if (cooldown > 0) {
            const timer = setTimeout(() => {
                setCooldown(cooldown - 1);
            }, 1000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [cooldown]);

    useEffect(() => {
        if (showSuccessMessage) {
            const timer = setTimeout(() => {
                setCooldown(time);
                setShowSuccessMessage(false);
            }, 3000);
            return () => {
                clearTimeout(timer);
            }
        }
    }, [showSuccessMessage, time]);

    const onClickHandler = useCallback((e) => {
        e.preventDefault();
        onClick(e);
        setShowSuccessMessage(true);
    }, [onClick]);

    return (
        <Button onClick={onClickHandler}
                disabled={cooldown > 0 || showSuccessMessage}
                {...buttonProps}
        >
            {
                showSuccessMessage
                    ? successMessage
                    : cooldown > 0
                        ? `${cooldownMessage} 00:${formatSeconds(cooldown)}`
                        : children
            }
        </Button>
    )
}

export default ButtonCooldown;
