import styled from 'styled-components';

export const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    background: var(--background-base);
    padding: 0 16px;
    margin-bottom: 24px;

    .logo {
        font-size: 22px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 8px;

        img {
            height: 24px;
        }
    }

    .menu {
        flex: 1;
    }
`;

export const HeaderAccountStyled = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    
    .logout {
        display: flex;
        height: 40px;
        font-size: 14px;
        font-weight: 600;
        align-items: center;

        color: var(--color-white-60);

        svg {
            fill: var(--color-white-60);
            transition: fill .15s ease-in-out;
            margin-left: 8px;
        }

        &:hover {
            color: var(--color-white-95);

            svg {
                fill: var(--color-white-95);
            }
        }
    }
`;

export const HeaderBurgerStyled = styled.div`
    a {
        display: block;
        color: white;
        text-decoration: none;
    }
`;
