import styled from "styled-components";

export const LoaderContainerStyled = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ bg }) => bg ? bg : 'rgba(0, 0, 0, 0.3)'};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; // TODO внимательно проверить где может сломаться!
`;
