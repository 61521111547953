import { createGlobalStyle } from "styled-components";

export const GlobalOverlaysStyled = createGlobalStyle`
    .popover {
        background-color: unset;
        border: none;
        max-width: none;
    }

    .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .bs-popover-top > .popover-arrow::after {
        border-top-color: var(--color-purple-highlight);
        bottom: 2px;
    }

    .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after {
        border-bottom-color: var(--color-purple-highlight);
        top: 2px;
    }

    .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .bs-popover-start > .popover-arrow::after {
        border-left-color: var(--color-purple-highlight);
    }

    .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .bs-popover-end > .popover-arrow::after {
        border-right-color: var(--color-purple-highlight);
    }

    .offcanvas.show {
        //z-index: 9999;
    }

    .offcanvas {
        background-color: var(--color-base-secondary);
        overflow-y: auto;

        &.offcanvas-end {
            border-left: none;
        }

        .dropdown-menu {
            width: 100%;
            top: 3rem;
        }
    }

    .offcanvas-end {
        padding: 0;
    }

    .offcanvas-bottom {
        height: unset;
        border-radius: 16px 16px 0px 0px;
        padding: 16px;
    }

    .offcanvas-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0 16px;
    }

    .offcanvas + .offcanvas-backdrop {
        z-index: 1050;
    }

    .offcanvas + .offcanvas-backdrop + .offcanvas {
        z-index: 1060;
    }


    .dropdown-menu {
        background-color: var(--color-surface-secondary);
        border: none;
        box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
    }

    .dropdown-divider {
        margin: 0;
        border-top: 1px solid var(--color-main);
        opacity: 1;
    }

    .dropdown-header {
        font-size: 14px;
        font-weight: 400;
        color: var(--text-color-secondary);
        padding: 12px 16px;
    }

    .dropdown-item {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-white);
        padding: 12px 16px;

        &.secondary {
            color: var(--text-color-secondary);

            &:hover,
            &:focus {
                color: var(--text-color-secondary);
            }
        }

        &.active,
        &:active {
            color: var(--color-white);
            background-color: var(--color-surface);
        }

        &:hover,
        &:focus {
            color: var(--color-white);
            background-color: var(--color-surface);
        }
    }
`;