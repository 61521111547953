import React, { useCallback, useMemo } from 'react';
import { useLocation } from "react-router";
import { Modal } from "react-bootstrap";
import { MODALS_NAME } from "./modalsConstants";
import PartnerUpdatePostbackModal from "../PartnerPostbacks/PartnerUpdatePostbackModal.jsx";
import PartnerAddPostbackModal from "../PartnerPostbacks/PartnerAddPostbackModal.jsx";
import { useDispatch } from "react-redux";
import { closeModalAction } from "../../app/appActions.js";
import CampaignAddModal from '../Campaigns/CampaignAddModal';
import CampaignUpdateModal from '../Campaigns/CampaignUpdateModal';

export const MODALS_LOG_ACTION_TYPES = {
    OPEN: 'open',
    TAB: 'tab',
};

const Modals = (props) => {
    const {search} = useLocation();
    const dispatch = useDispatch();

    const [openedName, openedTab] = useMemo(() => {
        const params = new URLSearchParams(search);
        return [params.get('m'), params.get('tab')];
    }, [search]);

    const closeModalHandler = useCallback((payload) => {
        dispatch(closeModalAction(payload));
    }, [dispatch]);

    return <>
        {/*TODO CFD-1481 Вынести модалки постбэков в модуль Postbacks по аналогии как в Campaigns*/}
        {
            openedName === MODALS_NAME.PARTNER_ADD_POSTBACK &&
            <Modal show
                   onHide={closeModalHandler}
                   centered
            >
                <PartnerAddPostbackModal/>
            </Modal>
        }
        {
            openedName === MODALS_NAME.PARTNER_UPDATE_POSTBACK &&
            <Modal show
                   onHide={closeModalHandler}
                   centered
            >
                <PartnerUpdatePostbackModal/>
            </Modal>
        }
    </>;
};

export default Modals;
