import { ClientJS } from "clientjs";
import Cookies from 'universal-cookie';

const QueryString = function () {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    const query_string = {};
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        // If first entry with this name
        if (typeof query_string[pair[0]] === "undefined") {
            query_string[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof query_string[pair[0]] === "string") {
            const arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
            query_string[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            query_string[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return query_string;
}();

const cookies = new Cookies();

if (QueryString.hasOwnProperty('refcode') || QueryString.hasOwnProperty('r') || QueryString.hasOwnProperty('af_sub4')) {
    const refcode = cookies.get('refcode');
    if (!refcode) {
        cookies.set('refcode', (QueryString.refcode || QueryString.r || QueryString.af_sub4), { path: '/', maxAge: 60 * 60 * 24 * 365 });
    }
}
toCookie('a');
toCookie('ac');
toCookie('ch');
toCookie('af_id', 60 * 60 * 24);

const client = new ClientJS();
const hash = client.getFingerprint();
const browser = client.getBrowserData();
cookies.set('fp', hash, { path: '/', maxAge: 60 * 60 * 24 * 365 });
cookies.set('br', browser, { path: '/' });

function toCookie(param, maxAge = 60 * 60 * 24 * 365) {
    if (QueryString.hasOwnProperty(param)) {
        const value = cookies.get(param);
        if (value === undefined) {
            cookies.set(param, QueryString[param], { path: '/', maxAge: maxAge });
        }
    }
}