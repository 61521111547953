import {PopoverArrowStyled, TooltipContentStyled} from "./styled/TooltipStyled";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {memo} from "react";

export const Tooltip = memo(({arrowColor, children, text, tooltipComponent, arrowStyle, ...triggerProps}) => (
        <OverlayTrigger
                overlay={(props) => {
                    return (
                            <Popover {...props} arrowProps={{style: {display: 'none'}}}>
                                <PopoverArrowStyled
                                        color={arrowColor}
                                        className="popover-arrow"
                                        {...props.arrowProps}
                                        style={{...props.arrowProps?.style, ...arrowStyle}}
                                />
                                {!!text && <TooltipContentStyled>{text}</TooltipContentStyled>}
                                {!!tooltipComponent && tooltipComponent}
                            </Popover>
                    );
                }}
                {...triggerProps}
        >
            {children}
        </OverlayTrigger>
))