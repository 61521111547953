export const APP_INIT_START = 'APP_INIT_START';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_FAILURE = 'APP_INIT_FAILURE';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const BREAKPOINTS = {
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200,
    extraExtraLarge: 1400,
}

export const LOCALE_CODES = {
    pt: 'pt',
    id: 'id',
    en: 'en',
};

export const RouterPaths = {
    campaigns: '/campaigns',
    confirm: '/confirm',
    forgot: '/forgot',
    help: '/help',
    login: '/login',
    main: '/',
    offers: '/offers/:offer_id?/:module?',
    partner: '/partner',
    payouts: '/payouts',
    postbacks: '/postbacks',
    promo: '/promo',
    register: '/register',
    reset: '/reset',
    settings: '/settings',
    statistics: '/statistics',
};

export const LINKS = {
    affiliateAgreementDoc: '/docs/affiliate-agreement.pdf',
    communicationsPolicyFxDoc: '/docs/communications-policy.pdf',
    privacyPolicyDoc: '/docs/privacy-policy.pdf',
}
