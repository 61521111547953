import styled from "styled-components";
import { BREAKPOINTS } from "../../../app/appConstants";

export const ModalInformationStyled = styled.div`
    background: var(--app-bg-modal-color);
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
`;

export const ModalWrapperStyled = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.medium}px) {
        flex-direction: column;
    }
`;

export const ModalContentStyled = styled.div`
    padding: 32px;
`;

export const ModalInformationContentStyled = styled.div`
    padding: 0 40px 40px;
`;
