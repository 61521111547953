import { useLangContext } from '../../providers/LangProvider/LangProviderContext';
import { useCallback, useMemo } from 'react';
import { postRequest } from '../../app/request';
import { usePopupContext } from '../Popup/PopupContext';
import { PARTNER_OFFERS_STATUS } from '../Partner/partnerConstants';
import {
    OfferInfoConditionsStyled,
    OfferInfoContentHeaderStyled,
    OfferInfoContentStyled,
    OfferInfoContentTitleContainerStyled,
    OfferInfoControlPanelStyled,
    OfferInfoReferralLinkStyled,
    OfferInfoStatusIndicatorStyled,
    OfferInfoStatusStyled,
    OfferInfoStyled,
    OfferInfoTypeStyled,
} from './styled/OfferInfoStyled';
import classnames from 'classnames';
import amountFormat from '../../utils/amountFormat';
import { Button } from 'react-bootstrap';
import Campaigns from '../Campaigns/Campaigns';
import OfferReferralLink from './OfferReferralLink';
import { useResponsive } from "../../components/responsive/index.js";
import PartnerPostbacks from "../PartnerPostbacks/PartnerPostbacks";

function OfferInfo(props) {
    const { offerInfo, getOffersList } = props;
    const { isLargeMinWidth } = useResponsive();
    const { getLangPath } = useLangContext();
    const { catchPopup } = usePopupContext();
    const isTypeCustom = !offerInfo.ftd && !offerInfo.turnover;

    const isOfferActive = useMemo(() => [PARTNER_OFFERS_STATUS.ACTIVE, PARTNER_OFFERS_STATUS.UPDATING].includes(offerInfo.status), [offerInfo.status]);
    const isOfferWaiting = useMemo(() => [PARTNER_OFFERS_STATUS.WAITING, PARTNER_OFFERS_STATUS.UPDATING].includes(offerInfo.status), [offerInfo.status]);
    const isOfferUpdating = useMemo(() => PARTNER_OFFERS_STATUS.UPDATING === offerInfo.status, [offerInfo.status]);

    const currentType = useMemo(() => ([
        offerInfo.ftd ? 'CPA' : undefined,
        offerInfo.turnover ? 'IB' : undefined,
        !offerInfo.ftd && !offerInfo.turnover ? 'Custom' : undefined,
    ].join(' ')), [offerInfo]);

    const newType = useMemo(() => ([
        offerInfo.ftd_new ? 'CPA' : undefined,
        offerInfo.turnover_new ? 'IB' : undefined,
        !offerInfo.ftd_new && !offerInfo.turnover_new ? 'Custom' : undefined,
    ].join(' ')), [offerInfo]);

    const handleApplyClick = useCallback(() => {
        postRequest(`/partner/offers/${offerInfo.offer_id}/apply`, {})
            .then(() => getOffersList())
            .catch(e => {
                catchPopup(e.response);
            });
    }, [offerInfo, catchPopup, getOffersList]);

    const handleDeclineClick = useCallback(() => {
        const isConfirmed = window.confirm('Are you sure you want to decline offer?');
        if (isConfirmed) {
            postRequest(`/partner/offers/${offerInfo.offer_id}/decline`, {})
                .then(() => getOffersList())
                .catch(e => {
                    catchPopup(e.response);
                });
        }
    }, [catchPopup, offerInfo, getOffersList]);

    return <OfferInfoStyled>
        <OfferInfoContentStyled>
            <OfferInfoContentHeaderStyled>
                <OfferInfoContentTitleContainerStyled>
                    <OfferInfoTypeStyled className={currentType !== newType ? 'has-updating' : ''}>
                        {currentType}
                    </OfferInfoTypeStyled>
                    {
                        (currentType !== newType) &&
                        <OfferInfoTypeStyled>
                            {newType}
                        </OfferInfoTypeStyled>
                    }
                    <h2 className='mb-0'>{offerInfo.title}</h2>
                </OfferInfoContentTitleContainerStyled>
                <OfferInfoStatusStyled className={classnames({
                    positive: offerInfo.status === PARTNER_OFFERS_STATUS.ACTIVE,
                    negative: [PARTNER_OFFERS_STATUS.CANCELED, PARTNER_OFFERS_STATUS.DECLINED].includes(offerInfo.status),
                })}>
                    <span>{getLangPath(`offers.statuses.${offerInfo.status}`)}</span>
                    <OfferInfoStatusIndicatorStyled className='indicator'/>
                </OfferInfoStatusStyled>
            </OfferInfoContentHeaderStyled>

            <OfferInfoConditionsStyled>
                <table>
                    <tbody>
                    {
                        isTypeCustom && currentType === newType && <tr>
                            <th>
                                Fixed payments on individual conditions
                            </th>
                        </tr>
                    }
                    {
                        (!!offerInfo.ftd || !!offerInfo.ftd_new) && <tr>
                            <th>{getLangPath('offers.ftd_payout')}</th>
                            <td className={isOfferUpdating ? 'has-updating' : ''}>
                                {offerInfo.ftd ? amountFormat(offerInfo.ftd_amount, 0, '$') : `-`}
                            </td>
                            {
                                isOfferUpdating && <td>
                                    {offerInfo.ftd_new ? amountFormat(offerInfo.ftd_amount_new, 0, '$') : '-'}
                                </td>
                            }
                        </tr>
                    }
                    {
                        (!!offerInfo.turnover || !!offerInfo.turnover_new) && <>
                            <tr>
                                <th>{getLangPath('offers.turnover_amount')}</th>
                                <td className={isOfferUpdating ? 'has-updating' : ''}>
                                    {offerInfo.turnover ? amountFormat(offerInfo.turnover_amount, 0, '$') : `-`}
                                </td>
                                {
                                    isOfferUpdating && <td>
                                        {offerInfo.turnover_new ? amountFormat(offerInfo.turnover_amount_new, 0, '$') : '-'}
                                    </td>
                                }
                            </tr>
                            <tr>
                                <th>{getLangPath('offers.turnover_lot')}</th>
                                <td className={isOfferUpdating ? 'has-updating' : ''}>
                                    {offerInfo.turnover ? amountFormat(offerInfo.turnover_lot, 0, '$') : `-`}
                                </td>
                                {
                                    isOfferUpdating && <td>
                                        {offerInfo.turnover_new ? amountFormat(offerInfo.turnover_lot_new, 0, '$') : '-'}
                                    </td>
                                }
                            </tr>
                        </>
                    }
                    </tbody>
                </table>
            </OfferInfoConditionsStyled>

            {
                isOfferActive &&
                <OfferInfoReferralLinkStyled>
                    <OfferReferralLink offerInfo={offerInfo}/>
                </OfferInfoReferralLinkStyled>
            }
        </OfferInfoContentStyled>

        {
            isOfferActive && <>
                <Campaigns offerInfo={offerInfo}/>

                <PartnerPostbacks offerId={offerInfo.id}/>
            </>
        }

        {
            isOfferWaiting && <OfferInfoControlPanelStyled>
                <Button variant='primary' onClick={handleApplyClick} size={isLargeMinWidth ? 'lg' : 'sm'}>
                    {getLangPath(offerInfo.status === PARTNER_OFFERS_STATUS.WAITING ? 'offers.apply_btn' : 'offers.apply_update_btn')}
                </Button>
                <Button variant='outline-danger' onClick={handleDeclineClick} className='decline'
                        size={isLargeMinWidth ? 'lg' : 'sm'}>
                    {getLangPath(offerInfo.status === PARTNER_OFFERS_STATUS.WAITING ? 'offers.decline_btn' : 'offers.decline_update_btn')}
                </Button>
            </OfferInfoControlPanelStyled>
        }

    </OfferInfoStyled>;
}

export default OfferInfo;
