import styled from 'styled-components';

export const ProfileStyled = styled.div`
    .accordion, .accordion-button {
        background: var(--color-surface);
    }
    .accordion-button {
        border: 1px solid var(--color-surface);
    }
`;
