import { FormControl } from "react-bootstrap";
import { useCallback, useState } from "react";
import { BinIcon } from "../Icon/Icon.jsx";

function FormPasswordEye(props) {
    const {
        disabled,
        isInvalid,
        iconColor = 'var(--color-secondary-active)',
        iconDisabledColor = 'var(--color-secondary-highlight)',
        ...otherProps
    } = props;
    const [show, setShow] = useState();
    const clickHandler = useCallback(() => setShow(!show), [show]);

    return <div style={{position: 'relative'}} className={isInvalid ? 'is-invalid' : ''}>
        <FormControl type={show ? 'text' : 'password'} disable={disabled} isInvalid={isInvalid} {...otherProps} style={{
            paddingRight: '3rem'
        }}/>
        <BinIcon style={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '1rem',
            fill: disabled ? iconDisabledColor : iconColor,
        }} name={show ? 'IcEyeOn' : 'IcEyeOff'} onClick={clickHandler}/>
    </div>
}

export default FormPasswordEye;
