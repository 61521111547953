import { createGlobalStyle } from "styled-components";

export const GlobalFormsStyled = createGlobalStyle`
    .form-control {
        color: #fff;
        background: var(--app-bg-color);
        border: 1px solid var(--app-bg-color);
        box-sizing: border-box;
        border-radius: 12px;
        padding: 16px;

        &:active,
        &:hover {
            color: #fff;
            border: 1px solid var(--color-main);
        }

        &:focus {
            outline: 0;
            color: #fff;
            border: 1px solid var(--color-main);
            background: var(--app-bg-color);
            box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        }

        &:disabled, &[readonly] {
            background: var(--app-bg-color);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
    }

    .form-label {
        font-weight: 400;
        color: var(--color-white-60);
    }

    .form-valid-hint {
        display: none;
        color: #dc3545;
    }

    .form-control:not(:placeholder-shown):not(:focus):invalid {
        + .form-valid-hint {
            display: block;
        }
    }

    .form-control:not(:placeholder-shown):not(:focus):invalid,
    .form-control.is-invalid,
    .was-validated .form-control:invalid {
        background-image: none;
        border-color: #dc3545;
    }

    .form-check {
        min-height: 24px;
        padding-left: 36px;

        label {
            color: #A3A6CC;
        }
    }

    .form-check .form-check-input {
        margin-left: -36px;
    }

    .form-check-input {
        width: 24px;
        height: 24px;
        margin-top: 0;
        margin-right: 0;
    }

    .form-check-input[type=checkbox] {
        border-radius: 8px;
        background-color: transparent;
        border: 1px solid var(--icon-primary-color);
        color: #0A0A10;

        &:hover {
            border: 1px solid var(--color-yellow);
        }

        &:checked {
            background-color: var(--color-yellow);
            border-color: var(--color-yellow);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230A0A10' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

            &:hover {
                background-color: var(--color-yellow-light);
                border: 1px solid var(--color-yellow-light);
            }
        }
    }

    .form-check .form-check-input[type=radio] {
        background-color: transparent;
        border: 1px solid var(--color-main);

        &:hover {
            border: 1px solid var(--color-yellow);
        }

        &:checked {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.8' fill='%2342456C'/%3e%3c/svg%3e");
            background-color: var(--color-yellow);
            border-color: var(--color-yellow);
        }
    }


    .form-switch {
        .form-check-input[type=checkbox] {
            width: 48px;
            height: 28px;
            margin-top: 0;
            margin-right: 0;
            background-color: var(--color-purple-highlight);
            border: none;
            border-radius: 8px;
            box-shadow: none;
            background-image: url('${process.env.PUBLIC_URL}/assets/icons/switch-circle.svg');

            &:checked {
                background-color: var(--color-yellow);
            }
        }

        .form-label {
            margin-bottom: 0;
        }
    }

    .form-select {
        color: var(--color-white);
        background: var(--app-bg-color);
        border: 1px solid var(--app-bg-color);
        background-image: url("/assets/icons/arrow-down.svg");
        background-repeat: no-repeat;
        background-position: right 10px center;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 16px 30px 16px 16px;
        position: relative;
    }
`