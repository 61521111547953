import { applyMiddleware, compose, createStore } from 'redux';
import rootReducers from './rootReducers';
import { coreSagas } from './coreSagas';

import middlewares, { sagaMiddleware } from './middlewares';

const enhancers = [applyMiddleware(...middlewares)];

const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        // eslint-disable-next-line no-underscore-dangle
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const appStore = createStore(rootReducers, {}, composeEnhancers(...enhancers));

// should be after createStore
sagaMiddleware.run(coreSagas);

export default appStore;
