import styled from "styled-components";

export const SelectCountryContainerStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid var(--color-base);
    border-radius: 12px;
    color: var(--text-color-secondary);
    background: var(--color-base);
    position: relative;
    cursor: pointer;
    width: 100%;
    ${({ isOpen }) => isOpen && 'border-color: var(--color-main);'}
    ${({ isError }) => isError && 'border-color: var(--color-red);'}
    
    :hover {
        border-color: var(--color-main);
    }
    :after {
        display: none;
    }
    
    .select-country__selected-name {
        color: var(--color-white);
    }
`;

export const SelectCountryRightIconContainerStyled = styled.div`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);

    svg {
        fill: ${({ isOpen }) => isOpen ? 'var(--color-white)' : 'var(--text-color-secondary)' };
        ${({ isOpen }) => isOpen && 'transform: rotate(180deg);'}
        transition: transform 200ms ease, fill 200ms ease;
    }
`;

export const SelectCountrySliderRowContainerStyled = styled.div`
    cursor: pointer;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-surface-secondary);
    display: flex;
    align-items: center;
    &:hover {
        background-color: var(--color-main);
    }
    &.disabled {
        color: #adb5bd;
        pointer-events: none;
        background-color: transparent;
    }
`;

export const SelectCountryInfoImgStyled = styled.img`
    width: 24px;
    border-radius: 8px;
`;
