import { Fragment, useCallback, useEffect, useState } from 'react';
import { getRequest } from '../../app/request';
import _ from 'lodash';
import OfferInfo from './OfferInfo';
import { usePopupContext } from '../Popup/PopupContext.js';
import { OffersContainerStyled } from './styled/OffersStyled';

function Offers() {
    const [offersList, setOffersList] = useState([]);
    const { catchPopup } = usePopupContext();

    const getOffersList = useCallback(() => {
        getRequest(`/partner/offers/list`).then(res => {
            setOffersList(res);
        }).catch(e => {
            catchPopup(e.response);
        });
    }, [catchPopup]);

    useEffect(() => {
        getOffersList();
    }, [getOffersList]);

    return !!offersList && <>
        <OffersContainerStyled>
            {_.sortBy(offersList, 'id').map(item => <Fragment key={item.id}>
                <OfferInfo offerInfo={item} getOffersList={getOffersList}/>
            </Fragment>)}
        </OffersContainerStyled>
    </>;
}

export default Offers;
