import 'bootstrap/dist/css/bootstrap.min.css';
import { ConnectedRouter } from "connected-react-router";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import App from './App';
import appHistory from "./app/appHistory";
import appStore from "./app/appStore";
import './app/token.js';
import './polyfills';
import './components/DatePicker/datePickerProvider';
import './fonts/fonts-open-sans.css';

ReactDOM.render(
    <Provider store={appStore}>
        <ConnectedRouter history={appHistory}>
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
