import styled from 'styled-components';
import { BREAKPOINTS } from "../../../../app/appConstants";

export const LoginStyled = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 32px 40px 48px;
    border-radius: 2px;
    
    background: var(--app-bg-modal-color);
    
    h4 {
        font-size: 40px;
        font-weight: 600;
    }
    
    @media(max-width: ${BREAKPOINTS.small}px) {
        padding: 24px 16px;
    }
`;

export const LoginBoxAcceptTermsContainerStyled = styled.div`
    font-weight: 400;
    label a {
        color: var(--text-color-secondary);
        font-weight: 600;
    }
    .form-check .form-check-input[type=checkbox].is-invalid {
        border-color: var(--color-negative);
    }
    .form-check-input.is-invalid~.form-check-label {
        color: var(--text-color-secondary);
    }
`;

export const LoginForgotPasswordLinkContainerStyled = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 32px;
    
    a {
        color: var(--color-white);
    }
`;
