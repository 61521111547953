/**
 * Преобразует в человекопонятный вид чисел с разбивкой на разряды (1 000 000)
 *
 * @param amount
 * @param fixed
 * @param currency
 * @returns {string}
 */
import _ from "lodash";

const amountFormat = (amount = 0, fixed = 2, currency = null, withLetters = false, options = {}) => {
    amount = parseFloat(amount);
    options.currencyBefore = options.currencyBefore || currency || '';
    options.withLetters = options.withLetters || withLetters;
    options.withSign = options.withSign || false;
    let letter = '';
    const currencyAfter = options.currencyAfter || '';
    if (options.withLetters) {
        if (Math.abs(amount) > 9999) {
            fixed = 0;
            letter = 'K';
            amount = _.round(amount / 1000);
        } else if (Math.abs(amount) > 9999999) {
            fixed = 0;
            letter = 'M';
            amount = _.round(amount / 1000000);
        } else if (Math.abs(amount) > 9999999999) {
            fixed = 0;
            letter = 'B';
            amount = _.round(amount / 1000000000);
        }
    }
    let sign = '';
    if (options.withSign) {
        sign = amount > 0 ? '+': amount < 0 ? '-': '';
        amount = Math.abs(amount);
    } else if (options.currencyBefore) {
        sign = amount < 0 ? '-': '';
        amount = Math.abs(amount);
    }
    return `${sign}${options.currencyBefore}` + parseFloat(amount).toFixed(fixed).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') + letter + currencyAfter;
}

export default amountFormat;
