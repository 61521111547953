import styled from 'styled-components';

export const CampaignFormWrapperStyled = styled.div`
    background-color: var(--color-surface);
    
    .form-control {
        padding: 12px 16px;
    }
`;

export const CampaignFormControlPanelStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    
    .btn-outline, .btn-outline-danger {
        padding: 12px;
    }
`;
