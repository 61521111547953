import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getOpenedModalParamsSelector } from "../../app/appSelectors";
import { closeModalAction } from "../../app/appActions";
import { useCallback, useEffect } from "react";
import {
    getPartnerIsUpdatePostbackLoadingSelector,
    getPartnerUpdatePostbackErrorSelector
} from "../Partner/partnerSelectors";
import { resetUpdatePartnerPostbackErrorAction, updatePartnerPostbackAction } from "../Partner/partnerActions";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { ModalContentStyled, ModalInformationStyled } from "../Modals/styled/ModalsStyled.js";
import PartnerPostbackForm from "./PartnerPostbackForm.jsx";
import { PARTNER_POSTBACK_FORM_TYPES } from "./partnerPostbacksConstants.js";

function PartnerUpdatePostbackModal(props) {
    const {
        closeModalAction,
        isUpdatePostbackLoading,
        openedModalParams,
        resetUpdatePartnerPostbackErrorAction,
        updatePostbackError,
        updatePartnerPostbackAction,
    } = props;
    const {getLangPath} = useLangContext();

    useEffect(() => {
        if (!openedModalParams) {
            closeModalAction();
        }
        return () => {
            resetUpdatePartnerPostbackErrorAction();
        }
    }, [closeModalAction, openedModalParams, resetUpdatePartnerPostbackErrorAction]);

    const handleSubmit = useCallback((data) => {
        updatePartnerPostbackAction(data);
    }, [updatePartnerPostbackAction]);

    return <ModalInformationStyled>
        <Modal.Header closeButton>
            {getLangPath(`postbacks.form.${PARTNER_POSTBACK_FORM_TYPES.UPDATE}.title`)}
        </Modal.Header>
        <ModalContentStyled>
            <PartnerPostbackForm
                campaignId={openedModalParams?.campaignId}
                error={updatePostbackError}
                item={openedModalParams?.item}
                formType={PARTNER_POSTBACK_FORM_TYPES.UPDATE}
                offerId={openedModalParams?.offerId}
                onCancel={closeModalAction}
                onSubmit={handleSubmit}
                onUnMount={resetUpdatePartnerPostbackErrorAction}
                isLoading={isUpdatePostbackLoading}
                resetError={resetUpdatePartnerPostbackErrorAction}
            />
        </ModalContentStyled>
    </ModalInformationStyled>
}

const mapStateToProps = createStructuredSelector({
    isUpdatePostbackLoading: getPartnerIsUpdatePostbackLoadingSelector,
    openedModalParams: getOpenedModalParamsSelector,
    updatePostbackError: getPartnerUpdatePostbackErrorSelector,
});

const mapDispatchToProps = {
    closeModalAction,
    resetUpdatePartnerPostbackErrorAction,
    updatePartnerPostbackAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerUpdatePostbackModal);
