import { useDispatch, useSelector } from "react-redux";
import { getAppLoggedIn, getAppPartner } from "../Auth/authSelectors.js";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RouterPaths } from "../../app/appConstants";
import { Fragment, useCallback } from "react";
import { logoutAction } from "../Auth/authActions.js";
import { HeaderAccountStyled, HeaderBurgerStyled, HeaderStyled } from "./styled/HeaderStyled.js";
import appHistory from "../../app/appHistory.js";
import Menu from "../Menu/Menu.jsx";
import amountFormat from "../../utils/amountFormat.js";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { BinIcon } from '../../components/Icon/Icon';
import { useResponsive } from "../../components/responsive/index.js";
import { Slider } from "../../components/Slider/Slider.jsx";
import { MENU_ITEMS } from "../Menu/menuConstants.js";
import { generatePath } from "react-router";

function Header() {
    const { getLangPath } = useLangContext();
    const loggedIn = useSelector(getAppLoggedIn);
    const dispatch = useDispatch();
    const partnerInfo = useSelector(getAppPartner);
    const { isLargeMinWidth } = useResponsive();

    const logoutHandler = useCallback(() => {
        dispatch(logoutAction());
    }, [dispatch]);

    const logoClickHandler = useCallback(() => {
        appHistory.push(RouterPaths.main);
    }, []);

    return <HeaderStyled>
        <div className="logo" onClick={logoClickHandler}>
            <img src={`${process.env.PUBLIC_URL}/assets/affilers_logotype.svg`} alt=""/>
        </div>
        {
            !!isLargeMinWidth && <>
                <div className="menu">
                    {
                        !!loggedIn && <Menu/>
                    }
                </div>
            </>
        }
        <HeaderAccountStyled>

            {
                !!loggedIn && <div className="f14 fw500">${amountFormat(partnerInfo.balance)}</div>
            }

            {
                !!isLargeMinWidth && loggedIn && <div>
                    <Button variant="outline" className="logout" onClick={logoutHandler}>
                        {getLangPath('menu.logout')}
                        <BinIcon name="IcLogOut"/>
                    </Button>
                </div>
            }

            {
                !isLargeMinWidth && loggedIn && <Slider
                    toggleComponent={
                        <BinIcon name={'IcMenuBurger'} stroke={'#ffffff'}/>
                    }
                    toggleWrapperClassname="flex-grow-0"
                >
                    <Slider.CloseWrapper>
                        <HeaderBurgerStyled>
                            {
                                MENU_ITEMS.map(item => <Fragment key={item.name}>
                                    <Link to={generatePath(item.path)} className={'dropdown-item'}>
                                        {getLangPath(`menu.${item.name}`)}
                                    </Link>
                                </Fragment>)
                            }
                            <Slider.Row className={'dropdown-item'} onClick={logoutHandler}>
                                <Link to={'#/'}>
                                    {getLangPath('menu.logout')}
                                </Link>
                            </Slider.Row>
                        </HeaderBurgerStyled>
                    </Slider.CloseWrapper>
                </Slider>
            }
        </HeaderAccountStyled>
    </HeaderStyled>
}

export default Header;
