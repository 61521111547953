import { Link, Redirect } from "react-router-dom";
import { RouterPaths } from "../../../app/appConstants";
import { Button, Form, FormGroup } from "react-bootstrap";
import { Loader } from "../../../components/Loader/Loader.jsx";
import { connect } from "react-redux";
import { registrationAction } from "../authActions.js";
import { getAppLoggedIn, getAppLoggedInProcess, getAppRegistrationError } from "../authSelectors.js";
import { useCallback, useEffect, useState } from "react";
import { LoginStyled } from "./styled/LoginStyled.js";
import FormPasswordEye from "../../../components/FormPasswordEye/FormPasswordEye.jsx";
import { createStructuredSelector } from "reselect";
import { useLangContext } from "../../../providers/LangProvider/LangProviderContext.js";
import AuthWrapper from "./AuthWrapper.jsx";
import { getAvailableCountries } from "../../../app/appSelectors.js";
import axios from "axios";
import { SelectCountry } from "../../../components/SelectCountry/SelectCountry.jsx";
import RegistrationAcceptPolicy from "./RegistrationAcceptPolicy.jsx";

function Registration(props) {
    const {
        availableCountries,
        loggedIn,
        loggedInProcess,
        registrationError,

        registrationAction,
    } = props;
    const { getLangPath } = useLangContext();
    const [selectedIso, setSelectedIso] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const submitHandler = useCallback((e) => {
        e.preventDefault();
        registrationAction({
            password: e.target.querySelector('[name=password]').value,
            acceptRules: e.target.querySelector('[name=accept-rules]').checked,
            email: e.target.querySelector('[name=email]').value,
            phone: e.target.querySelector('[name=phone]')?.value,
            country: selectedCountry?.iso,
            fullname: e.target.querySelector('[name=fullname]')?.value,
        });
    }, [selectedCountry, registrationAction]);

    const handleSelectCountry = useCallback((iso) => {
        setSelectedIso(iso);
    }, []);

    useEffect(() => {
        axios.get('https://api.country.is/')
            .then(geo => {
                setSelectedIso(geo.data?.country);
            })
            .catch(() => {
                // nothing
            });
    }, []);

    useEffect(() => {
        if (availableCountries.length && selectedIso) {
            const found = availableCountries.find(item => item.iso === selectedIso.toLowerCase());
            found && setSelectedCountry(found);
        }
    }, [availableCountries, selectedIso]);

    if (loggedIn) {
        return <Redirect to={RouterPaths.main}/>
    }

    return <AuthWrapper>
        <LoginStyled>
            <Loader show={loggedInProcess}/>

            <Form.Group className="mb-5 mt-2">
                <h4 className="text-center">{getLangPath('auth.title_registration')}</h4>
            </Form.Group>
            <Form onSubmit={submitHandler}>
                <FormGroup className="mb-4">
                    <SelectCountry
                        availableCountries={availableCountries}
                        onSelect={handleSelectCountry}
                        selectedCountry={selectedCountry}
                    />
                </FormGroup>

                <Form.Group className="mb-4">
                    <Form.Control name="email" type="email" placeholder={getLangPath('auth.field_email')}
                                  required/>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Control name="fullname" type="text" placeholder={getLangPath('auth.field_fullname')}
                                  required/>
                </Form.Group>

                <Form.Group className="mb-4">
                    <Form.Control name="phone" type="text" placeholder={getLangPath('auth.field_phone')}
                                  required/>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicPassword">
                    <FormPasswordEye name="password" placeholder={getLangPath('auth.field_password')}
                                     autoComplete={'off'}
                                     required/>
                </Form.Group>

                {
                    !!registrationError && <>
                        <p className={'text-danger'}>
                            {getLangPath(registrationError)}
                        </p>
                    </>
                }

                <RegistrationAcceptPolicy className="mb-4 mt-4"/>

                <div className="d-grid mb-2">
                    <Button variant={'primary'} size={'lg'} type="submit" className="mb-4">
                        {getLangPath('auth.registration_submit')}
                    </Button>
                    <Button variant={'outline-info'} size={'md'} as={Link} to={RouterPaths.login} type="button">
                        {getLangPath('auth.link_to_login')}
                    </Button>
                </div>
            </Form>
        </LoginStyled>
    </AuthWrapper>
}

const mapStateToProps = createStructuredSelector({
    availableCountries: getAvailableCountries,
    loggedIn: getAppLoggedIn,
    loggedInProcess: getAppLoggedInProcess,
    registrationError: getAppRegistrationError,
});

const mapDispatchToProps = (dispatch) => ({
    registrationAction: (payload) => dispatch(registrationAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
