import { Button } from "react-bootstrap";
import { useCallback } from "react";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { BinIcon } from "../Icon/Icon.jsx";
import { Tooltip } from "../Tooltip/Tooltip.jsx";

function CopyReferralLink(props) {
    const {className, iconSize, referralLink} = props;
    const {getLangPath} = useLangContext();

    const handleCopyReferralLink = useCallback(() => {
        navigator.clipboard.writeText(referralLink).then();
    }, [referralLink]);

    return (
            <Tooltip text={getLangPath('profile.copy_referral_link')}>
                <Button
                        onClick={handleCopyReferralLink}
                        variant="info"
                        size="sm"
                        className={className}
                >
                    <BinIcon name="IcDocCopy" size={iconSize}/>
                </Button>
            </Tooltip>
    )
}

export default CopyReferralLink
