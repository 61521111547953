import { forwardRef } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import {
    SelectCountryContainerStyled,
    SelectCountryInfoImgStyled,
    SelectCountryRightIconContainerStyled,
    SelectCountrySliderRowContainerStyled
} from './styled/SelectCountryStyled';
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import classnames from "classnames";
import { useResponsive } from "../responsive/index.js";
import { Slider } from "../Slider/Slider.jsx";
import { BinIcon } from "../Icon/Icon.jsx";

const CountryInfo = ({name, iso, className}) => {
    const {getLangPath} = useLangContext();
    return (
        <>
            <SelectCountryInfoImgStyled
                src={`${process.env.PUBLIC_URL}/assets/flag-${iso}.svg`}
                className={classnames("me-2", className)}
                alt={''}
            />
            <span className="select-country__selected-name"> {getLangPath(name)}</span>
        </>
    );
}

const SelectCountryToggle = ({onClick, selectedCountry, error, className, toggleIcon, ...props}) => {
    const {getLangPath} = useLangContext();
    const {'aria-expanded': isOpen} = props;
    return (
        <div className="d-flex flex-column flex-1">
            <SelectCountryContainerStyled
                className={className}
                isOpen={isOpen}
                isError={error === 'error.select_country'}
                onClick={onClick}
            >
                {selectedCountry
                    ? <CountryInfo iso={selectedCountry.iso} name={selectedCountry.name} className="me-3"/>
                    : <>
                        <BinIcon name={toggleIcon} style={{marginRight: '16px'}}/>
                        <span className="select-country__toggle-label">{getLangPath('auth.select_country')}</span>
                    </>
                }
                <SelectCountryRightIconContainerStyled isOpen={isOpen}>
                    <BinIcon name="IcChevronDown" className="select-country__arrow-icon"/>
                </SelectCountryRightIconContainerStyled>
            </SelectCountryContainerStyled>
            {error === 'error.select_country' && (
                <Form.Control.Feedback className="d-block" type="invalid">{getLangPath(error)}</Form.Control.Feedback>
            )}
        </div>
    );
}

const SelectCountryDropdownToggle = forwardRef((props, ref) => (
    <div ref={ref}>
        <SelectCountryToggle {...props}/>
    </div>
));

const SelectCountryDropdown = ({availableCountries, error, onSelect, selectedCountry, toggleIcon}) => {
    return (
        <Dropdown onSelect={onSelect}>
            <Dropdown.Toggle selectedCountry={selectedCountry} as={SelectCountryDropdownToggle} error={error}
                             toggleIcon={toggleIcon}/>

            <Dropdown.Menu style={{width: '100%'}}>
                {availableCountries.map((item) => (
                    <Dropdown.Item key={item.iso} eventKey={item.iso} style={{width: '100%'}}>
                        <CountryInfo iso={item.iso} name={item.name}/>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
};

const SelectCountrySlider = ({availableCountries, error, onSelect, selectedCountry, toggleIcon}) => (
    <Slider toggleComponent={<SelectCountryToggle error={error} selectedCountry={selectedCountry}
                                                  toggleIcon={toggleIcon}/>}>
        {availableCountries.map((item) => (
            <Slider.Row key={item.iso} onClick={() => onSelect(item.iso)}>
                <SelectCountrySliderRowContainerStyled>
                    <CountryInfo iso={item.iso} name={item.name}/>
                </SelectCountrySliderRowContainerStyled>
            </Slider.Row>
        ))}
    </Slider>
);

export const SelectCountry = ({availableCountries, error, onSelect, selectedCountry, toggleIcon = 'IcFlags'}) => {
    const {isSmallMaxWidth} = useResponsive();
    const SelectCountryComponent = isSmallMaxWidth ? SelectCountrySlider : SelectCountryDropdown;
    return (
        <SelectCountryComponent
            availableCountries={availableCountries}
            error={error}
            onSelect={onSelect}
            selectedCountry={selectedCountry}
            toggleIcon={toggleIcon}
        />
    );
};
