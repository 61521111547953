import styled from 'styled-components';

export const OfferReferralLinkStyled = styled.div`
    display: inline-flex;
    align-items: center;
    color: var(--color-link);
    font-weight: 400;
    
    span {
        word-break: break-all;
    }

    .copy-button {
        font-size: 10px;
        padding: 4px;
        border-radius: 8px;
        background: var(--background-neutral-10);
        border: 1px solid var(--background-neutral-10);
        margin-left: 24px;
    }
`;
