import axios from 'axios';
import pjson from '../../package.json';
import { API_URL } from "./api";

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
};

function getHeaders(json) {
    const headers = {
        // "Cookie": document.cookie,
        "X-CLIENT-VER": pjson.version,
    };
    if (json) {
        headers['Content-Type'] = 'application/json; charset=UTF-8';
    }
    return headers;
}

export function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        if (response.data.status === 'fail') {
            const error = new Error(response.data.statusText);
            error.response = response;
            throw error;
        }
        return response.data;
    }
    const error = new Error(response.data.error.message);
    error.response = response;
    throw error;
}

export function checkReject(failed) {
    const error = Error(failed.response?.data?.error?.message || failed.response?.data?.message);
    error.response = failed.response;
    throw error;
}

const request = (url, method, optionsInit = {}) => {
    const options = Object.assign({}, optionsInit);
    options.headers = getHeaders(true);
    options.credentials = 'include';

    return axios({
        url: API_URL + url,
        method,
        ...options,
    })
        .catch(checkReject)
        .then(checkStatus);
};

export const getRequest = (url, params = {}, options = {}) =>
    request(url, METHODS.GET, { params, ...options });

export const postRequest = (url, body, params = {}, options = {}) =>
    request(url, METHODS.POST, { data: body, params, ...options });

export const putRequest = (url, body, params = {}, options = {}) =>
    request(url, METHODS.PUT, { data: body, params, ...options });

export const deleteRequest = (url, body, params = {}, options = {}) =>
    request(url, METHODS.DELETE, { data: body, params, ...options });
