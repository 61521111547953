import { createBrowserHistory } from 'history';
import formPathWithSearchParams from "../utils/formPathWithSearchParams.js";

const appHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });

export const appHistoryPushWithSearchParams = (params, pathname = '') => {
    appHistory.push(formPathWithSearchParams(params, pathname));
}

export const appHistoryReplaceWithSearchParams = (params, pathname = '') => {
    appHistory.replace(formPathWithSearchParams(params, pathname));
}

export default appHistory;
