import { useCallback } from "react";
import appHistory from "./appHistory";
import { PageErrorIconContainerStyled, PageErrorLinkStyled } from "./styled/PageErrorStyled.js";
import { BinIcon } from "../components/Icon/Icon.jsx";

function PageErrorContent(props) {
    const {
        children,
        code = '404',
        description,
        error = null,
        headerText = 'Page not found',
        icon = 'IcUnknownError',
        iconComponent,
        linkText = 'Go back to previous page',
    } = props;

    const handleClick = useCallback(() => appHistory.goBack(), [])

    return <div className="d-flex flex-column align-items-center">
        {
            iconComponent || (
                <PageErrorIconContainerStyled className="mb-3">
                    <BinIcon name={icon} style={{ fontSize: '48px' }}/>
                </PageErrorIconContainerStyled>
            )
        }
        <h1 className="mb-4 text-center">{headerText}</h1>
        {!!description && <p className="text-center" dangerouslySetInnerHTML={{ __html: description }}/>}
        {
            children || (
                <PageErrorLinkStyled onClick={handleClick}>
                    {linkText}
                </PageErrorLinkStyled>
            )
        }
    </div>
}

export default PageErrorContent;
