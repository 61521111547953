import { useLangContext } from "../../../providers/LangProvider/LangProviderContext";
import { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { RouterPaths } from "../../../app/appConstants";
import AuthWrapper from "./AuthWrapper.jsx";
import { LoginStyled } from "./styled/LoginStyled.js";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction } from "../authActions.js";
import { getPasswordResetErrorSelector, getPasswordResetOkSelector } from "../authSelectors.js";
import { useLocation } from "react-router";
import useEffectOnce from "../../../hooks/useEffectOnce.js";
import appHistory from "../../../app/appHistory.js";

function Reset() {
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const passwordResetOk = useSelector(getPasswordResetOkSelector);
    const passwordResetError = useSelector(getPasswordResetErrorSelector);

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    useEffect(() => {
        if (passwordResetOk) {
            appHistory.push(RouterPaths.login);
        }
    }, [passwordResetOk]);

    useEffectOnce(() => {
        const params = new URLSearchParams(search);
        setEmail(params.get('email'));
        setCode(params.get('code'));
        dispatch(resetPasswordAction({
            check: true,
            email: params.get('email'),
            code: params.get('code'),
        }));
    });

    const submitHandler = useCallback((e) => {
        e.preventDefault();
        dispatch(resetPasswordAction({
            email,
            code,
            new_password: e.target.querySelector('[name=new_password]').value,
            new_password_repeat: e.target.querySelector('[name=new_password_repeat]').value,
        }));
    }, [code, dispatch, email]);

    return <AuthWrapper>
        <LoginStyled>
            <Form.Group className="mb-5 mt-2">
                <h4 className="text-center">{getLangPath('auth.reset_password.title_reset')}</h4>
            </Form.Group>
            <Form onSubmit={submitHandler}>
                <Form.Group className={'mb-4'}>
                    <Form.Control name="new_password" type="password"
                                  placeholder={getLangPath('auth.reset_password.new_password')}
                                  required/>
                </Form.Group>
                <Form.Group className={'mb-4'}>
                    <Form.Control name="new_password_repeat" type="password"
                                  placeholder={getLangPath('auth.reset_password.new_password_repeat')}
                                  required/>
                </Form.Group>

                {
                    !!passwordResetError && <>
                        <p className={'text-danger'}>
                            {getLangPath(passwordResetError)}
                        </p>
                    </>
                }

                <div className="d-grid mb-2">
                    <Button variant={'primary'} size={'lg'} type="submit" className="mb-4">
                        {getLangPath('auth.reset_password.save_password_button')}
                    </Button>
                </div>
            </Form>
        </LoginStyled>
    </AuthWrapper>
}

export default Reset;
