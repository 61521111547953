import _ from "lodash";
import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { useDispatch, useSelector } from "react-redux";
import {
    getPartnerIsStatisticsLoading,
    getPartnerStatistics,
    getPartnerStatisticsTotal
} from "../Partner/partnerSelectors.js";
import {
    PARTNER_STATISTICS_CAMPAIGN_COLUMNS_GROUP_TYPES,
    PARTNER_STATISTICS_GROUP_TYPE,
    PARTNER_STATISTICS_REGISTERED_AT_COLUMN_GROUP_TYPES,
    PARTNER_STATISTICS_REGISTERED_COLUMN_GROUP_TYPES
} from "./statisticsConstants.js";
import {
    DATE_PRESETS_CONFIG,
    DEFAULT_FILTER_PARAMS,
    formatEndDate,
    formatRegisteredAtColumn,
    formatStartDate,
    GROUP_CONFIG
} from "./statisticsUtils.js";
import { loadPartnerStatisticsAction } from "../Partner/partnerActions.js";
import amountFormat from "../../utils/amountFormat.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Button, Form, FormGroup, Table } from "react-bootstrap";
import {
    PartnerStatisticsFilterColumnStyled,
    PartnerStatisticsFilterContainerStyled,
    PartnerStatisticsMainColumnStyled,
    PartnerStatisticsMainRowStyled,
    PartnerStatisticsPresetContainerStyled,
    StatisticsStyled,
    StatisticsTableStyled
} from "./styled/StatisticsStyled.js";
import DatePicker from "../../components/DatePicker/DatePicker.jsx";
import { SectionWrapperStyled } from '../../components/Section/styled/SectionStyled';

const SORTED_GROUP_BY_CONFIG_VALUES = _.sortBy(Object.values(GROUP_CONFIG), 'order');

function Statistics() {
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();
    const statistics = useSelector(getPartnerStatistics);
    const statisticsTotal = useSelector(getPartnerStatisticsTotal);
    const isPartnerStatisticsLoading = useSelector(getPartnerIsStatisticsLoading);

    const [startDate, setStartDate] = useState(DEFAULT_FILTER_PARAMS.getStartDate());
    const [endDate, setEndDate] = useState(DEFAULT_FILTER_PARAMS.getEndDate());
    const [groupType, setGroupType] = useState(DEFAULT_FILTER_PARAMS.group_type);
    const groupTypeConfig = useMemo(() => GROUP_CONFIG[groupType], [groupType]);

    useEffect(() => {
        dispatch(loadPartnerStatisticsAction({
            group_type: DEFAULT_FILTER_PARAMS.group_type,
            start_date: formatStartDate(DEFAULT_FILTER_PARAMS.getStartDate()),
            end_date: formatEndDate(DEFAULT_FILTER_PARAMS.getEndDate()),
            campaign_name: DEFAULT_FILTER_PARAMS.campaign_name,
            campaign_channel: DEFAULT_FILTER_PARAMS.campaign_channel,
        }));
    }, [dispatch])

    const handleDateStartChange = useCallback((date) => {
        setStartDate(date);
        if (moment(endDate).isBefore(moment(date))) {
            setEndDate(date);
        }
    }, [endDate]);

    const handleDateEndChange = useCallback((date) => {
        setEndDate(date);
    }, []);

    const handlePresetClick = useCallback((e) => {
        const presetName = e.currentTarget.dataset.name;
        const preset = _.find(DATE_PRESETS_CONFIG, { name: presetName });
        setStartDate(preset?.startDate()?.toDate() || null);
        setEndDate(preset?.endDate()?.toDate() || null);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const currentGroupType = e.target.querySelector('[name=group_type]:checked').value;
        setGroupType(currentGroupType);
        dispatch(loadPartnerStatisticsAction({
            group_type: currentGroupType,
            start_date: formatStartDate(startDate),
            end_date: formatEndDate(endDate),
            campaign_name: e.target.querySelector('[name=campaign_name]').value,
            campaign_channel: e.target.querySelector('[name=campaign_channel]').value,
        }));
    }, [endDate, dispatch, startDate]);

    const formatGroupColumnValue = useCallback((value) => {
        return groupTypeConfig.format(value);
    }, [groupTypeConfig]);

    const isShowCampaignsColumns = useMemo(() =>
        PARTNER_STATISTICS_CAMPAIGN_COLUMNS_GROUP_TYPES.includes(groupType), [groupType]);
    const isShowRegisteredColumn = useMemo(() =>
        PARTNER_STATISTICS_REGISTERED_COLUMN_GROUP_TYPES.includes(groupType), [groupType]);
    const isShowRegisteredAtColumn = useMemo(() =>
        PARTNER_STATISTICS_REGISTERED_AT_COLUMN_GROUP_TYPES.includes(groupType), [groupType]);

    return <StatisticsStyled>
        <SectionWrapperStyled>
            <Form onSubmit={handleSubmit}>
                <PartnerStatisticsMainRowStyled>
                    <PartnerStatisticsMainColumnStyled className="order-xl-1 order-2">
                        <PartnerStatisticsFilterContainerStyled>
                            <PartnerStatisticsFilterColumnStyled className="flex-grow-1 flex-column d-flex">
                                <Form.Label>Start date</Form.Label>
                                <DatePicker
                                    selectedDate={startDate}
                                    onChange={handleDateStartChange}
                                    popperPlacement="bottom"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                />
                            </PartnerStatisticsFilterColumnStyled>
                            <PartnerStatisticsFilterColumnStyled className="flex-grow-1 flex-column d-flex">
                                <Form.Label>End date</Form.Label>
                                <DatePicker
                                    selectedDate={endDate}
                                    onChange={handleDateEndChange}
                                    popperPlacement="bottom"
                                    minDate={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="DD/MM/YYYY"
                                />
                            </PartnerStatisticsFilterColumnStyled>
                        </PartnerStatisticsFilterContainerStyled>
                    </PartnerStatisticsMainColumnStyled>
                    <PartnerStatisticsMainColumnStyled className="order-xl-2 order-1">
                        <PartnerStatisticsFilterContainerStyled>
                            <PartnerStatisticsFilterColumnStyled className="flex-grow-1 d-flex flex-column">
                                <Form.Label>{getLangPath('campaigns.campaign_name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="campaign_name"
                                    defaultValue={DEFAULT_FILTER_PARAMS.campaign_name}
                                    placeholder={getLangPath('statistics.campaign_name_placeholder')}/>
                            </PartnerStatisticsFilterColumnStyled>

                            <PartnerStatisticsFilterColumnStyled className="flex-grow-1 d-flex flex-column">
                                <Form.Label>{getLangPath('campaigns.campaign_channel')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="campaign_channel"
                                    defaultValue={DEFAULT_FILTER_PARAMS.campaign_channel}
                                    placeholder={getLangPath('statistics.campaign_channel_placeholder')}/>
                            </PartnerStatisticsFilterColumnStyled>
                        </PartnerStatisticsFilterContainerStyled>
                    </PartnerStatisticsMainColumnStyled>
                </PartnerStatisticsMainRowStyled>
                <PartnerStatisticsMainRowStyled>
                    <PartnerStatisticsMainColumnStyled>
                        <PartnerStatisticsPresetContainerStyled>
                            {
                                DATE_PRESETS_CONFIG.map(preset => {
                                    return <Button
                                        key={preset.name}
                                        variant='link'
                                        size='sm'
                                        data-name={preset.name}
                                        onClick={handlePresetClick}
                                    >
                                        {getLangPath(preset.title)}
                                    </Button>
                                })
                            }
                        </PartnerStatisticsPresetContainerStyled>
                    </PartnerStatisticsMainColumnStyled>
                    <PartnerStatisticsMainColumnStyled>
                        <FormGroup>
                            <Form.Label className="mb-0">Group by</Form.Label>
                            <div>
                                {
                                    SORTED_GROUP_BY_CONFIG_VALUES.map(({ type, title }) => (
                                        <Form.Check
                                            defaultChecked={type === PARTNER_STATISTICS_GROUP_TYPE.DAY}
                                            key={type}
                                            inline
                                            type="radio"
                                            name="group_type"
                                            id={type}
                                            value={type}
                                            className="mt-2"
                                            label={getLangPath(title)}
                                        />
                                    ))
                                }
                            </div>
                        </FormGroup>
                    </PartnerStatisticsMainColumnStyled>
                </PartnerStatisticsMainRowStyled>
                <div className="d-flex justify-content-start mt-2">
                    <Button variant="primary" size="md" type="submit" disabled={isPartnerStatisticsLoading}>
                        {getLangPath('statistics.apply_filter')}
                    </Button>
                </div>
            </Form>
        </SectionWrapperStyled>
        <StatisticsTableStyled>
            <Table striped bordered hover variant="dark" className="mt-5 mb-5">
                <thead>
                <tr>
                    <th>{getLangPath(groupTypeConfig.title)}</th>
                    {isShowRegisteredAtColumn && <th>{getLangPath('statistics.registered_at')}</th>}
                    {isShowCampaignsColumns && <th>{getLangPath('campaigns.campaign_name')}</th>}
                    {isShowCampaignsColumns && <th>{getLangPath('campaigns.campaign_channel')}</th>}
                    {isShowRegisteredColumn && <th>{getLangPath('statistics.registered')}</th>}
                    {<th>{getLangPath('statistics.ftd')}</th>}
                    {<th>{getLangPath('statistics.turnover')}</th>}
                    <th>{getLangPath('statistics.profit')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    statistics.map((item, index) =>
                        <tr key={index}>
                            <td>{formatGroupColumnValue(item[groupTypeConfig.field]) || ''}</td>
                            {isShowRegisteredAtColumn && <td>{formatRegisteredAtColumn(item.registered_at)}</td>}
                            {isShowCampaignsColumns && <td>{item.campaign_name || ''}</td>}
                            {isShowCampaignsColumns && <td>{item.campaign_channel || ''}</td>}
                            {isShowRegisteredColumn && <td>{item.registered}</td>}
                            {<td>{item.ftd}</td>}
                            {<td>${amountFormat(item.turnover)}</td>}
                            <td>${amountFormat(item.profit)}</td>
                        </tr>
                    )
                }
                </tbody>
                <tfoot>
                <tr>
                    <td>{getLangPath('statistics.total')}</td>
                    {isShowRegisteredAtColumn && <td></td>}
                    {isShowCampaignsColumns && <td></td>}
                    {isShowCampaignsColumns && <td></td>}
                    {isShowRegisteredColumn && <td>{statisticsTotal.registered}</td>}
                    {<td>{statisticsTotal.ftd}</td>}
                    {<td>${amountFormat(statisticsTotal.turnover)}</td>}
                    <td>${amountFormat(statisticsTotal.profit)}</td>
                </tr>
                </tfoot>
            </Table>
        </StatisticsTableStyled>
    </StatisticsStyled>
}

export default Statistics;
