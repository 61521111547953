import { useLangContext } from "../../providers/LangProvider/LangProviderContext.js";
import { useDispatch, useSelector } from "react-redux";
import { getAppPartner } from "../Auth/authSelectors.js";
import { Accordion, Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { refreshApiKeyAction, userUpdateAction, userUpdatePasswordAction } from "../Partner/partnerActions.js";
import { SelectCountry } from "../../components/SelectCountry/SelectCountry.jsx";
import { getAvailableCountries } from "../../app/appSelectors.js";
import {
    getPartnerIsRefreshApiKeyLoadingSelector,
    getUserUpdateErrorSelector,
    getUserUpdatePasswordErrorSelector
} from "../Partner/partnerSelectors.js";
import { SectionWrapperStyled } from '../../components/Section/styled/SectionStyled';
import { ProfileStyled } from './styled/ProfileStyled';

function Partner() {
    const { getLangPath } = useLangContext();
    const dispatch = useDispatch();
    const partnerInfo = useSelector(getAppPartner);
    const availableCountries = useSelector(getAvailableCountries);
    const userUpdateError = useSelector(getUserUpdateErrorSelector);
    const userUpdatePasswordError = useSelector(getUserUpdatePasswordErrorSelector);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const isRefreshApiKeyLoading = useSelector(getPartnerIsRefreshApiKeyLoadingSelector);

    const handleRefreshApiKeyButtonClick = useCallback(() => {
        const isConfirmed = window.confirm(getLangPath('profile.refresh_api_key_confirm'));
        if (isConfirmed) {
            dispatch(refreshApiKeyAction());
        }
    }, [getLangPath, dispatch]);

    useEffect(() => {
        if (!selectedCountry && availableCountries.length) {
            const found = availableCountries.find(item => item.iso === partnerInfo.country);
            found && setSelectedCountry(found);
        }
    }, [availableCountries, partnerInfo.country, selectedCountry]);

    const submitProfileHandler = useCallback((e) => {
        e.preventDefault();
        const params = {
            full_name: e.target.querySelector('[name=full_name]').value,
            email: e.target.querySelector('[name=email]').value,
            phone: e.target.querySelector('[name=phone]').value,
        };
        dispatch(userUpdateAction(params));
    }, [dispatch]);

    const submitChangePasswordHandler = useCallback((e) => {
        e.preventDefault();
        const params = {
            old_password: e.target.querySelector('[name=old_password]').value,
            new_password: e.target.querySelector('[name=new_password]').value,
            new_password_repeat: e.target.querySelector('[name=new_password_repeat]').value,
        };
        dispatch(userUpdatePasswordAction(params));
    }, [dispatch]);

    const handleSelectCountry = useCallback((iso) => {
        const found = availableCountries.find(item => item.iso === iso);
        found && setSelectedCountry(found);
        dispatch(userUpdateAction({
            country: found.iso,
        }));
    }, [availableCountries, dispatch]);

    return <ProfileStyled>
        <FormGroup className={'mb-4'}>
            <SectionWrapperStyled>
                <h4>{getLangPath('profile.title')}</h4>
                <Form onSubmit={submitProfileHandler}>
                    <FormGroup as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm={4}>{getLangPath('profile.country')}</Form.Label>
                        <Col><SelectCountry
                            availableCountries={availableCountries}
                            onSelect={handleSelectCountry}
                            selectedCountry={selectedCountry}
                        /></Col>
                    </FormGroup>
                    <FormGroup as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm={4}>{getLangPath('profile.full_name')}</Form.Label>
                        <Col><Form.Control type="text"
                                           name="full_name"
                                           defaultValue={partnerInfo.full_name}
                                           required
                        /></Col>
                    </FormGroup>
                    <FormGroup as={Row} className="mb-3 align-items-center">
                        <Form.Label column sm={4}>{getLangPath('profile.email')}</Form.Label>
                        <Col><Form.Control type="email"
                                           name="email"
                                           defaultValue={partnerInfo.email}
                                           required
                        /></Col>
                    </FormGroup>
                    <FormGroup as={Row} className="mb-2 align-items-center">
                        <Form.Label column sm={4}>{getLangPath('profile.phone')}</Form.Label>
                        <Col><Form.Control type="text"
                                           name="phone"
                                           defaultValue={partnerInfo.phone}
                                           required
                        /></Col>
                    </FormGroup>
                    {
                        !!userUpdateError && <p className={'text-danger'}>
                            {getLangPath(userUpdateError)}
                        </p>
                    }
                    <Button variant={'primary'} type="submit">
                        {getLangPath('profile.save_profile')}
                    </Button>
                </Form>
            </SectionWrapperStyled>
        </FormGroup>
        <FormGroup className={'mb-3'}>
            <Accordion defaultActiveKey={'0'}>
                <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>{getLangPath('profile.change_password_title')}</Accordion.Header>
                    <Accordion.Body>
                        <Form onSubmit={submitChangePasswordHandler}>
                            <FormGroup as={Row} className="mb-3 align-items-center">
                                <Form.Label column sm={4}>{getLangPath('profile.old_password')}</Form.Label>
                                <Col>
                                    <Form.Control type="password"
                                                  name="old_password"/>
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} className="mb-3 align-items-center">
                                <Form.Label column sm={4}>{getLangPath('profile.new_password')}</Form.Label>
                                <Col>
                                    <Form.Control type="password"
                                                  name="new_password"/>
                                </Col>
                            </FormGroup>
                            <FormGroup as={Row} className="mb-3 align-items-center">
                                <Form.Label column sm={4}>{getLangPath('profile.new_password_repeat')}</Form.Label>
                                <Col>
                                    <Form.Control type="password"
                                                  name="new_password_repeat"/>
                                </Col>
                            </FormGroup>
                            {
                                !!userUpdatePasswordError && <p className={'text-danger'}>
                                    {getLangPath(userUpdatePasswordError)}
                                </p>
                            }
                            <Button variant={'primary'} type="submit">
                                {getLangPath('profile.save_change_password')}
                            </Button>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </FormGroup>
        <FormGroup className="mb-3">
            <span className="text-secondary">{getLangPath('profile.partner_id')}</span>
            <h6>{partnerInfo.partner_uid}</h6>
            <span className="text-secondary">{getLangPath('profile.api_key')}</span>
            <h6 style={{display: "block", overflow: "auto"}}>{partnerInfo.api_key}</h6>
            <Button
                disabled={isRefreshApiKeyLoading}
                onClick={handleRefreshApiKeyButtonClick}
                variant="info"
                size="sm"
            >
                {getLangPath('profile.refresh_api_key_button')}
            </Button>
        </FormGroup>
    </ProfileStyled>
}

export default Partner;
