import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { renderToString } from "react-dom/server.js";
import { BinIcon } from "../../components/Icon/Icon.jsx";
import { LangProviderContext } from "./LangProviderContext.js";
import Cookies from "universal-cookie";
import { createStructuredSelector } from "reselect";
import { appInitAction } from "../../app/appActions.js";
import { connect } from "react-redux";
import moment from "moment";
import { getAppLang, getAppLocale, getAvailableCountries, getAvailableLocales } from "../../app/appSelectors.js";
import { userUpdateAction } from "../../modules/Partner/partnerActions.js";

const cookies = new Cookies();
const locale_in_mem = cookies.get('locale');

function LangProvider(props) {
    const {
        appInit,
        availableCountries,
        availableLocales,
        children,
        lang,
        locale,
        userUpdate,
    } = props;

    const [localeInner, setLocaleInner] = useState();

    const _setLocale = useCallback((_locale) => {
        cookies.set('locale', _locale, {path: '/', maxAge: 60 * 60 * 24 * 365});
        setLocaleInner(_locale);
        if (localeInner && localeInner !== _locale) {
            userUpdate({
                locale: _locale,
            });
        }
    }, [localeInner, setLocaleInner, userUpdate]);

    const _getLangPath = useCallback(
            (path = '', params = {}, options = {}) => {
                const emptyDefault = !!options.emptyDefault;
                let langString = _.get(lang, path, emptyDefault ? '' : path.toString());
                const paramsKeys = Object.keys(params);
                paramsKeys.forEach(paramKey => {
                    langString = langString.replaceAll(`{{${paramKey}}}`, params[paramKey])
                })
                langString = langString.replaceAll(/{{icon::([^:}]+)(::([^:}]+))?}}/ig, (...args) => {
                    return renderToString(<BinIcon name={args[1]} style={{fill: args[3] || null}}/>)
                });
                return langString;
            },
            [lang],
    );

    useEffect(() => {
        if (!locale) {
            if (!locale_in_mem) {
                const userLang = navigator.language || navigator.userLanguage;
                _setLocale(userLang.slice(0, 2));
            } else {
                setLocaleInner(locale_in_mem);
            }
        }
        document.documentElement.lang = locale;
        moment.locale(locale);
    }, [locale, _setLocale, setLocaleInner]);

    useEffect(() => {
        if (localeInner) {
            appInit();
        }
    }, [localeInner, appInit]);

    return <>
        <LangProviderContext.Provider value={{
            availableCountries,
            availableLocales,
            getLangPath: _getLangPath,
            locale,
            setLocale: _setLocale,
            setLang: _setLocale,
            lang,
        }}>
            {children}
        </LangProviderContext.Provider>
    </>
}

const mapStateToProps = createStructuredSelector({
    availableCountries: getAvailableCountries,
    availableLocales: getAvailableLocales,
    lang: getAppLang,
    locale: getAppLocale,
});

const mapDispatchToProps = (dispatch) => ({
    appInit: (payload) => dispatch(appInitAction(payload)),
    userUpdate: (payload) => dispatch(userUpdateAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangProvider);
