import styled from 'styled-components';
import Promo from "../../modules/Promo/Promo.jsx";

export const PagePromoStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 950px;
    margin: 0 auto;
`;

function PagePromo() {
    return <PagePromoStyled>
        <Promo/>
    </PagePromoStyled>
}

export default PagePromo;
