import { Link } from "react-router-dom";
import { LINKS } from "../../app/appConstants";

function PageSupport() {
    return <>
        <h4>Docs</h4>
        <p><Link to={LINKS.affiliateAgreementDoc} target={'__blank'}>Affiliate Agreement</Link></p>
        <p><Link to={LINKS.communicationsPolicyFxDoc} target={'__blank'}>Communications Policy</Link></p>
        <p><Link to={LINKS.privacyPolicyDoc} target={'__blank'}>Privacy Policy</Link></p>
        <h4>Support</h4>
        <p><a href={'mailto:support@affilers.com'} target={'__blank'}>support@affilers.com</a></p>
    </>
}

export default PageSupport;
